import angular from "angular";
import $ from 'jquery';
import moment from "moment";

angular
    .module('ui-sentinel.devices.latestDeviceTracking')
    .directive('latestDeviceTrackingFilterbar', LatestDeviceTrackingFilterbarDirective);

function LatestDeviceTrackingFilterbarDirective() {
    return {
        restrict: 'A',
        controller: ThisDirectiveController,
        controllerAs: 'controller',
        template: require('./latest-device-tracking-filterbar-directive.html')
    };
}

ThisDirectiveController.$inject = ['$scope', '$rootScope', '$state', 'SentinelUiSession', 'DateFormatService', 'TrackingFilterService', 'LatestDeviceTrackingReportsService', 'DatetimeValidatorService', 'UtilityService'];

function ThisDirectiveController($scope, $rootScope, $state, SentinelUiSession, DateFormatService, TrackingFilterService, LatestDeviceTrackingReportsService, DatetimeValidatorService, UtilityService) {
    const vm = {
        reportsService: LatestDeviceTrackingReportsService,
        filter: TrackingFilterService,
        error: null,
        fromDate: {
            label: null,
            value: null,
            error: null
        },
        toDate: {
            label: null,
            value: null,
            error: null
        },
        isOrs: $rootScope.isOrs,
        accountIsTopParent: SentinelUiSession.user.accountIsTopParent,
        navToDevicesMap: navToDevicesMap,
        navToDevicesReports: navToDevicesReports,
        resetDateRange: resetDateRange,
        resetPropertyFilter: resetPropertyFilter,
        submitDateRange: submitDateRange,
        toggleDateRangeForm: toggleDateRangeForm,
        toggleProperty: toggleProperty,
        toggleSeverity: toggleSeverity,
        refresh: refresh
    };

    const savedFromDate = UtilityService.getSetting(`${vm.reportsService.dateKey}.fromDate`);
    const savedToDate = UtilityService.getSetting(`${vm.reportsService.dateKey}.toDate`);
    if (savedFromDate) {
        vm.fromDate.value = savedFromDate;
        vm.fromDate.label = DateFormatService.format(vm.fromDate.value);
        vm.reportsService.fromDate = moment(savedFromDate);
    }
    if (savedToDate) {
        vm.toDate.value = savedToDate;
        vm.toDate.label = DateFormatService.format(vm.toDate.value);
        vm.reportsService.toDate = moment(savedToDate);
    }

    activate();
    return vm;

    ////////////////////////////////////////////////////////////////////////////

    function activate() {
        $scope.$watch(
            function () {
                return vm.reportsService.fromDate;
            },
            function (date) {
                refreshFromDate(date);
            }, true
        );
        $scope.$watch(
            function () {
                return vm.reportsService.toDate;
            },
            function (date) {
                refreshToDate(date);
            }, true
        );
        $scope.$watchCollection(
            function () {
                return vm.filter.filteredReports;
            },
            function (reports) {
                console.log("filteredReports changed", reports);
                if (reports.length === 0 && vm.filter.searchText && vm.filter.searchText.length > 0) {
                    // search in database
                    console.log(`Searching in database the pattern: ${vm.filter.searchText}`);
                    vm.reportsService.searchPatter(vm.filter.searchText);
                }
            }
        );
        $scope.$watch(
            function () {
                return vm.fromDate.value;
            },
            function (date) {
                UtilityService.saveSetting(`${vm.reportsService.dateKey}.fromDate`, date);
            }, true
        );
        $scope.$watch(
            function () {
                return vm.toDate.value;
            },
            function (date) {
                UtilityService.saveSetting(`${vm.reportsService.dateKey}.toDate`, date);
            }, true
        );
    }

    function refresh() {
        vm.reportsService.load(vm.reportsService.fromDate, vm.reportsService.toDate, true);
    }

    function refreshFromDate(date) {
        if (!date) {
            vm.fromDate.label = 'null';
            vm.fromDate.value = null;
            return;
        }
        const dateMoment = moment(date).startOf('minute');
        vm.fromDate.value = dateMoment.toDate();
        vm.fromDate.label = DateFormatService.format(vm.fromDate.value);
    }

    function refreshToDate(date) {
        if (!date) {
            vm.toDate.label = 'null';
            vm.toDate.value = null;
            return;
        }
        const dateMoment = moment(date).startOf('minute');
        vm.toDate.value = dateMoment.toDate();
        vm.toDate.label = DateFormatService.format(vm.toDate.value);
    }

    function resetDateRange() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;
        refreshFromDate(vm.reportsService.fromDate);
        refreshToDate(vm.reportsService.toDate);
    }

    function resetPropertyFilter() {
        vm.filter.range.above = vm.filter.range.min;
        vm.filter.range.below = vm.filter.range.max;
        vm.filter.range.from = vm.filter.range.min;
        vm.filter.range.to = vm.filter.range.max;
        $('#btn-reset-property').blur();
    }

    function submitDateRange() {
        validateDateRange();

        if (vm.fromDate.error || vm.toDate.error) {
            return;
        }

        vm.reportsService.load(vm.fromDate.value, vm.toDate.value);
    }

    function toggleDateRangeForm() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;

        refreshFromDate(vm.reportsService.fromDate);
        refreshToDate(vm.reportsService.toDate);
        vm.showDateRangeForm = !vm.showDateRangeForm;
    }

    function toggleProperty() {
        vm.filter.range.enabled = !vm.filter.range.enabled;
        $('#btn-property').blur();
    }

    function toggleSeverity(severity) {
        $rootScope.loading = true;
        _.defer(function () {
            switch (severity) {
                case 'ok':
                    vm.filter.showOk = !vm.filter.showOk;
                    break;
                case 'info':
                    vm.filter.showInfo = !vm.filter.showInfo;
                    break;
                case 'warning':
                    vm.filter.showWarning = !vm.filter.showWarning;
                    break;
            }
            $(`#btn-${severity}`).blur();

            $rootScope.loading = false;
            $rootScope.$apply();
        });
    }

    function navToDevicesMap() {
        $state.go('devices.map', { forceUseLastDateRange: true });
    }

    function navToDevicesReports() {
        $state.go('devices.reports');
    }

    function validateDateRange() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;

        const fromDateMoment = DatetimeValidatorService.toMomentDt(vm.fromDate.value);
        if (!fromDateMoment) {
            vm.fromDate.error = true;
            vm.error = `Start ${DatetimeValidatorService.dateError}`;
            return;
        }

        const toDateMoment = DatetimeValidatorService.toMomentDt(vm.toDate.value);
        if (!toDateMoment) {
            vm.toDate.error = true;
            vm.error = `End ${DatetimeValidatorService.dateError}`;
            return;
        }

        const oneYearAgo = moment().subtract(364, 'days');

        if (fromDateMoment.isBefore(oneYearAgo)) {
            vm.fromDate.error = true;
            vm.error = `Start date cannot be before ${DateFormatService.format(oneYearAgo.toDate())}`;
            return;
        }

        const fortyFiveDaysAfter = moment(fromDateMoment).add(45, 'day');
        if (toDateMoment.isAfter(fortyFiveDaysAfter)) {
            vm.toDate.error = true;
            vm.error = `End date cannot be after ${DateFormatService.format(fortyFiveDaysAfter.toDate())}`;
            return;
        }

        if (toDateMoment.isSame(fromDateMoment) || toDateMoment.isBefore(fromDateMoment)) {
            vm.toDate.error = true;
            vm.error = `End date must be after ${DateFormatService.format(fromDateMoment.toDate())}`;
            return;
        }

        vm.fromDate.value = fromDateMoment.toDate();
        vm.toDate.value = toDateMoment.toDate();
    }
}
