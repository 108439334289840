import angular from "angular";

angular
    .module('ui-common')
    .directive('apiDataSorter', ['$rootScope', function ($rootScope) {
        return {
            require: '?ngModel',
            restrict: 'E',
            replace: true,
            template: require('./data-sorter-template.html'),
            scope: {
                ngModel: '=',
                translations: '=',
                service: '=',
                columnDisplayName: '@',
                columnName: '@',
                dateColumn: '@'
            },
            link: function ($scope) {
                const dateColumn = $scope.dateColumn === 'true' || $scope.dateColumn === '';

                $scope.getName = () => dateColumn && $rootScope.isOrs
                    ? `${$scope.columnDisplayName} (UTC)`
                    : $scope.columnDisplayName

                $scope.thisColumnIsSortedAsc = false;
                $scope.thisColumnIsSortedDesc = false;

                const update = () => {
                    $scope.thisColumnIsSortedAsc = $scope.service.sortColumn === $scope.columnName && $scope.service.sortAsc;
                    $scope.thisColumnIsSortedDesc = $scope.service.sortColumn === $scope.columnName && !$scope.service.sortAsc;
                }
                $scope.$watch('service.sortAsc', () => {
                    update();
                });
                $scope.$watch('service.sortColumn', () => {
                    update();
                });
                update();
            }
        }
    }]);
