import angular from "angular";
import uirouter from '@uirouter/angularjs'
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import LocalStorageModule from 'angular-local-storage';

import './components/api-common/';
import './components/api-sentinel/';
import '../lib/angular-scanner-detection/angular.scannerdetection.js';

import './components/ui-sentinel/home/';
import './components/ui-sentinel/login/';
import './components/ui-sentinel/header/';
import './components/ui-sentinel/session/';

import './components/ui-common/';
import './components/ui-sentinel/accounts/';
import './components/ui-sentinel/logins/';
import './components/ui-sentinel/sentry/';
import './components/ui-sentinel/sentinel/';
import './components/ui-sentinel/simulators/';
import './components/ui-sentinel/sentry-reports/';
import './components/ui-sentinel/sentry-configs/';
import './components/ui-sentinel/sentry-commands/';
import './components/ui-sentinel/sightings/';
import './components/ui-sentinel/feedback/';
import './components/ui-sentinel/maps/';
import './components/ui-sentinel/alarms/';
import './components/ui-sentinel/geofences/';
import './components/ui-sentinel/shipments/';
import './components/ui-sentinel/watchlist/';
import './components/ui-sentinel/devicegroups/';
import './components/ui-sentinel/devices/';
import './components/ui-sentinel/locations/';
import './components/ui-sentinel/zones/';
import './components/ui-sentinel/assets/';
import './components/ui-sentinel/calibrations/';
import './components/ui-sentinel/fulfillment/';
import './components/ui-sentinel/common/';

import './vendor.js';

angular.module('sentinel.gateway.web', [
    uirouter,
    ngResource,
    ngSanitize,
    LocalStorageModule,
    'api-common',
    'api-sentinel',
    'scanner.detection',
    'ui-common',
    'ui-sentinel.home',
    'ui-sentinel.login',
    'ui-sentinel.header',
    'ui-sentinel.session',
    'ui-sentinel.accounts',
    'ui-sentinel.logins',
    'ui-sentinel.sentry',
    'ui-sentinel.sentinel',
    'ui-sentinel.simulators',
    'ui-sentinel.sentry-reports',
    'ui-sentinel.sentry-configs',
    'ui-sentinel.sentry-commands',
    'ui-sentinel.sightings',
    'ui-sentinel.feedback',
    'ui-sentinel.maps',
    'ui-sentinel.alarms',
    'ui-sentinel.geofences',
    'ui-sentinel.shipments',
    'ui-sentinel.watchlist',
    'ui-sentinel.devicegroups',
    'ui-sentinel.devices',
    'ui-sentinel.locations',
    'ui-sentinel.zones',
    'ui-sentinel.assets',
    'ui-sentinel.calibrations',
    'ui-sentinel.fulfillment',
    'checklist-model'
]);
