import angular from "angular";
import moment from "moment/moment";

angular
    .module('ui-sentinel.shipments.shipmentTracking')
    .directive('shipmentTrackingFilterbar', ShipmentTrackingFilterbarDirective);

function ShipmentTrackingFilterbarDirective() {
    return {
        restrict: 'A',
        controller: ThisDirectiveController,
        controllerAs: 'controller',
        template: require('./shipment-tracking-filterbar-directive.html')
    };
}

ThisDirectiveController.$inject = ['$scope', '$rootScope', '$state', 'SentinelUiSession', 'DateFormatService', 'TrackingFilterService', 'ShipmentTrackingReportsService', 'DatetimeValidatorService', 'UtilityService'];

function ThisDirectiveController($scope, $rootScope, $state, SentinelUiSession, DateFormatService, TrackingFilterService, ShipmentTrackingReportsService, DatetimeValidatorService, UtilityService) {
    const vm = {
        reportsService: ShipmentTrackingReportsService,
        filter: TrackingFilterService,
        error: null,
        fromDate: {
            label: null,
            value: null,
            error: null
        },
        toDate: {
            label: null,
            value: null,
            error: null
        },
        isOrs: $rootScope.isOrs,
        accountIsTopParent: SentinelUiSession.user.accountIsTopParent,
        navToShipmentMap: navToShipmentMap,
        navToShipmentReports: navToShipmentReports,
        navToShipmentSummary: navToShipmentSummary,
        navToShipmentAdmin: navToShipmentAdmin,
        refresh: refresh,
        resetDateRange: resetDateRange,
        resetPropertyFilter: resetPropertyFilter,
        submitDateRange: submitDateRange,
        toggleDateRangeForm: toggleDateRangeForm,
        toggleProperty: toggleProperty,
        toggleSeverity: toggleSeverity
    };

    const dateKey = vm.reportsService.dateKey;
    const savedFromDate = UtilityService.getSetting(`${dateKey()}.fromDate`);
    const savedToDate = UtilityService.getSetting(`${dateKey()}.toDate`);
    if (savedFromDate) {
        vm.fromDate.value = savedFromDate;
        vm.fromDate.label = DateFormatService.format(vm.fromDate.value);
        vm.reportsService.fromDate = moment(savedFromDate);
    }
    if (savedToDate) {
        vm.toDate.value = savedToDate;
        vm.toDate.label = DateFormatService.format(vm.toDate.value);
        vm.reportsService.toDate = moment(savedToDate);
    }

    activate();
    return vm;

    ////////////////////////////////////////////////////////////////////////////

    function activate() {
        $scope.$watch(
            function () {
                return vm.reportsService.fromDate;
            },
            function (date) {
                refreshFromDate(date);
            }, true
        );
        $scope.$watch(
            function () {
                return vm.reportsService.toDate;
            },
            function (date) {
                refreshToDate(date);
            }, true
        );
        $scope.$watch(
            function () {
                return vm.fromDate.value;
            },
            function (date) {
                UtilityService.saveSetting(`${dateKey()}.fromDate`, date);
            }, true
        );
        $scope.$watch(
            function () {
                return vm.toDate.value;
            },
            function (date) {
                UtilityService.saveSetting(`${dateKey()}.toDate`, date);
            }, true
        );
    }

    function refresh() {
        vm.reportsService.load(vm.reportsService.fromDate, vm.reportsService.toDate, true);
    }

    function refreshFromDate(date) {
        if (!date) {
            vm.fromDate.label = 'null';
            vm.fromDate.value = null;
            return;
        }
        const dateMoment = moment(date).startOf('minute');
        vm.fromDate.value = dateMoment.toDate();
        vm.fromDate.label = DateFormatService.format(vm.fromDate.value);
    }

    function refreshToDate(date) {
        if (!date) {
            vm.toDate.label = 'null';
            vm.toDate.value = null;
            return;
        }
        const dateMoment = moment(date).startOf('minute');
        vm.toDate.value = dateMoment.toDate();
        vm.toDate.label = DateFormatService.format(vm.toDate.value);
    }

    function resetDateRange() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;
        refreshFromDate(vm.reportsService.fromDate);
        refreshToDate(vm.reportsService.toDate);
    }

    function resetPropertyFilter() {
        vm.filter.range.above = vm.filter.range.min;
        vm.filter.range.below = vm.filter.range.max;
        vm.filter.range.from = vm.filter.range.min;
        vm.filter.range.to = vm.filter.range.max;
        $('#btn-reset-property').blur();
    }

    function submitDateRange() {
        validateDateRange();

        if (vm.fromDate.error || vm.toDate.error) {
            return;
        }

        vm.reportsService.load(moment(vm.fromDate.value), moment(vm.toDate.value), true);
    }

    function toggleDateRangeForm() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;

        refreshFromDate(vm.reportsService.fromDate);
        refreshToDate(vm.reportsService.toDate);
        vm.showDateRangeForm = !vm.showDateRangeForm;
    }

    function toggleProperty() {
        vm.filter.range.enabled = !vm.filter.range.enabled;
        $('#btn-property').blur();
    }

    function toggleSeverity(severity) {
        switch (severity) {
            case 'ok':
                vm.filter.showOk = !vm.filter.showOk;
                break;
            case 'info':
                vm.filter.showInfo = !vm.filter.showInfo;
                break;
            case 'warning':
                vm.filter.showWarning = !vm.filter.showWarning;
                break;
        }
        $(`#btn-${severity}`).blur();
    }

    function navToShipmentAdmin() {
        $state.go('shipment.admin', { shipmentId: $state.params.shipmentId });
    }

    function navToShipmentMap() {
        $state.go('shipment.map', { shipmentId: $state.params.shipmentId });
    }

    function navToShipmentReports() {
        $state.go('shipment.reports', { shipmentId: $state.params.shipmentId });
    }

    function navToShipmentSummary() {
        $state.go('shipment.summary', { shipmentId: $state.params.shipmentId });
    }

    function validateDateRange() {
        vm.fromDate.error = null;
        vm.toDate.error = null;
        vm.error = null;

        const fromDateMoment = DatetimeValidatorService.toMomentDt(vm.fromDate.value);
        if (!fromDateMoment) {
            vm.fromDate.error = true;
            vm.error = `Start ${DatetimeValidatorService.dateError}`;
            return;
        }

        const toDateMoment = DatetimeValidatorService.toMomentDt(vm.toDate.value);
        if (!toDateMoment) {
            vm.toDate.error = true;
            vm.error = `End ${DatetimeValidatorService.dateError}`;
            return;
        }

        const oneYearAgo = moment().subtract(364, 'days');

        if (fromDateMoment.isBefore(oneYearAgo)) {
            vm.fromDate.error = true;
            vm.error = `Start date cannot be before ${DateFormatService.format(oneYearAgo.toDate())}`;
            return;
        }

        const fortyFiveDaysAfter = moment(fromDateMoment).add(45, 'day');
        if (toDateMoment.isAfter(fortyFiveDaysAfter)) {
            vm.toDate.error = true;
            vm.error = `End date cannot be after ${DateFormatService.format(fortyFiveDaysAfter.toDate())}`;
            return;
        }

        if (toDateMoment.isSame(fromDateMoment) || toDateMoment.isBefore(fromDateMoment)) {
            vm.toDate.error = true;
            vm.error = `End date must be after ${DateFormatService.format(fromDateMoment.toDate())}`;
            return;
        }

        vm.fromDate.value = fromDateMoment.toDate();
        vm.toDate.value = toDateMoment.toDate();
    }
}
