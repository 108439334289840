import angular from "angular";
import "./header.css";

angular
    .module('ui-sentinel.header')
    .directive('sentinelUiHeader', HeaderDirective);

HeaderDirective.$inject = [];

function HeaderDirective() {
    var directive = {
        restrict: 'A',
        template: require('./header-directive.html'),
        link: function ($scope, elem, attrs) {

        }
    };
    return directive;
}
