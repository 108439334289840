import angular from "angular";

// field-filter-selector
angular
    .module('ui-common')
    .directive("fieldFilterSelector", () => ({
        restrict: 'E',
        replace: false,
        scope: {
            controller: '=',
        },
        template: require('./field-filter-selector-directive.html')
    }));
