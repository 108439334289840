import angular from "angular";
import get from "lodash/get";
import set from "lodash/set";

angular
    .module('ui-sentinel.session')
    .factory('UtilityService', UtilityService);

UtilityService.$inject = ['$rootScope', 'localStorageService'];

function UtilityService($rootScope, localStorageService) {
    return {
        getSetting,
        saveSetting
    };

    function getSetting(key) {
        const rootScopeSetting = get($rootScope, key);
        if (rootScopeSetting) {
            return rootScopeSetting;
        }
        const localStorageSetting = localStorageService.get(key);
        if (localStorageSetting) {
            return localStorageSetting;
        }
        return undefined;
    }

    function saveSetting(key, value) {
        set($rootScope, key, value);
        localStorageService.set(key, value);
    }
}
