import angular from "angular";
import $ from "jquery";

// side-bar-data-table
angular
    .module('ui-common')
    .directive("sideBarDataTable", () => ({
        restrict: 'E',
        replace: false,
        scope: {
            view: '@',
            reportsService: '=',
            sessionService: '=',
            formatLocation: '=',
            secondsService: '=',
            isOrs: "<",
            accountIsTopParent: "<",
        },
        template: require('./side-bar-data-table-directive.html'),
        controller: ThisDirectiveController,
        controllerAs: 'vm'
    }));

ThisDirectiveController.$inject = ['$rootScope', 'ClientReportsService', 'FeedbackService'];

function ThisDirectiveController($rootScope, ClientReportsService, FeedbackService) {
    const vm = this;
    vm.changeLatLongBegin = (report) => {
        console.log("Report to edit", report);
        vm.changeLatLongInProgress = true;
        vm.report = report;
        vm.newLatitude = report.latitude;
        vm.newLongitude = report.longitude;
        vm.updateAllMatching = report.latitude !== null && report.longitude !== null;
        vm.changeLatLongErrorMessage = null;
        FeedbackService.clear();
        document.getElementById('txt-newLatitude').focus();
    };
    vm.resetLatLong = (report) => {
        vm.newLatitude = report.latitude;
        vm.newLongitude = report.longitude;
        vm.updateAllMatching = report.latitude !== null && report.longitude !== null;
        FeedbackService.clear();
    };
    vm.changeLatLongCancel = () => {
        vm.changeLatLongErrorMessage = null;
        vm.changeLatLongInProgress = false;
        vm.report = null;
        vm.newLatitude = null;
        vm.newLongitude = null;
        vm.updateAllMatching = false;
        FeedbackService.clear();
    };
    vm.changeLatLongSubmit = (reportService, report) => {
        FeedbackService.clear();
        if (isNaN(vm.newLatitude)) {
            FeedbackService.addError('Latitude is not valid.');
            return;
        }
        if (isNaN(vm.newLongitude)) {
            FeedbackService.addError('Longitude is not valid.');
            return;

        }
        $rootScope.loading = true;
        const promise = ClientReportsService.changeLatLongForReport(report.reportGuid, vm.newLatitude, vm.newLongitude, vm.updateAllMatching).$promise;
        promise.then(
            function (updateList) {
                $('.modal').modal('hide');
                vm.changeLatLongErrorMessage = null;
                vm.changeLatLongInProgress = false;
                vm.report = null;
                vm.newLatitude = null;
                vm.newLongitude = null;
                vm.updateAllMatching = false;
                for (const sentryStatus of updateList) {
                    if (reportService.selected.reportGuid === sentryStatus.id) {
                        reportService.selected.latitude = sentryStatus.latitude;
                        reportService.selected.longitude = sentryStatus.longitude;
                        reportService.selected.locationMethod = sentryStatus.locationMethod;
                    }
                    const report = reportService.reports.find(r => r.reportGuid === sentryStatus.id);
                    if (report) {
                        report.latitude = sentryStatus.latitude;
                        report.longitude = sentryStatus.longitude;
                        report.locationMethod = sentryStatus.locationMethod;
                    }
                }
            },
            function (error) {
                const message = error.data ? error.data.message : 'An unknown error occurred while updating the location.';
                FeedbackService.addError(message);
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    };
}
