import angular from "angular";

// ui-utc
// <ui-utc/>
const uiUtcDirective = ($rootScope) => {
    return {
        restrict: 'E',
        scope: { value: '@' },
        template: '<span>{{ utc }}</span>',
        link: (scope) => scope.utc = $rootScope.isOrs ? '(UTC)' : ''
    };
}

angular.module('ui-common').directive('uiUtc', ['$rootScope', uiUtcDirective]);
