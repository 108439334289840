import angular from "angular";

// time-in-seconds-picker
// <time-in-seconds-picker/>
const timeInSecondsPicker = () => {
    return {
        restrict: 'E',
        scope: {
            ngModel: '='
        },
        template: `
            <table>
                <tr>
                    <td>Days</td>
                    <td>Hours</td>
                    <td>Minutes</td>
                    <td ng-show="seconds">Seconds</td>
                </tr>
                <tr>
                    <td><select class="form-select" ng-model="days" ng-options="n for n in daysOptions" ng-change="updateModel()"></select></td>
                    <td><select class="form-select" ng-model="hours" ng-options="n for n in hoursOptions" ng-change="updateModel()"></select></td>
                    <td><select class="form-select" ng-model="minutes" ng-options="n for n in minutesOptions" ng-change="updateModel()"></select></td>
                    <td ng-show="seconds"><select class="form-select" ng-model="seconds" ng-options="n for n in secondsOptions" ng-change="updateModel()"></select></td>
                <tr>
            </table>
        `,
        link: function (scope) {
            // Initialize dropdown options
            scope.daysOptions = Array.from({ length: 60 }, (v, k) => k);
            scope.hoursOptions = Array.from({ length: 24 }, (v, k) => k);
            scope.minutesOptions = Array.from({ length: 60 }, (v, k) => k);
            scope.secondsOptions = Array.from({ length: 60 }, (v, k) => k);

            const updateTime = () => {
                let totalSeconds = scope.ngModel || 0;

                scope.days = Math.floor(totalSeconds / 86400);
                totalSeconds %= 86400;
                scope.hours = Math.floor(totalSeconds / 3600);
                totalSeconds %= 3600;
                scope.minutes = Math.floor(totalSeconds / 60);
                scope.seconds = totalSeconds % 60;
            };

            scope.updateModel = function () {
                scope.ngModel = (scope.days * 86400) + (scope.hours * 3600) + (scope.minutes * 60) + scope.seconds;
            };

            scope.$watch('ngModel', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    updateTime();
                }
            });

            updateTime();
        }
    };
}

angular.module('ui-common').directive('timeInSecondsPicker', ['$rootScope', timeInSecondsPicker]);
