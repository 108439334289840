import angular from "angular";

angular
    .module('ui-common')
    .factory('TrackingFilterService', TrackingFilterService);

TrackingFilterService.$inject = ['$rootScope', '$state', 'localStorageService'];

function TrackingFilterService($rootScope, $state, localStorageService) {
    const localStorageKey = `${$state.current.data.pageTitle || ''}-${$state.current.data.parentState || ''}`;
    const filterSettings = localStorageService.get(localStorageKey);

    const batteryLabel = $rootScope.isOrs ? 'GPS Battery' : 'Battery';

    const filterShipments = shipment => {
        let isTextMatch = true;
        if (service.searchText) {
            isTextMatch = (
                shipment.deviceTagId.toLowerCase().indexOf(service.searchText.toString().toLowerCase()) > -1 ||
                shipment.referenceNumber.toLowerCase().indexOf(service.searchText.toString().toLowerCase()) > -1
            );
        }

        let isOriginMatch = true;
        if (service.shipments.origin) {
            isOriginMatch = shipment.origin.toLowerCase().indexOf(service.shipments.origin.toString().toLowerCase()) > -1;
        }

        let isDestinationMatch = true;
        if (service.shipments.destination) {
            isDestinationMatch = shipment.destination.toLowerCase().indexOf(service.shipments.destination.toString().toLowerCase()) > -1;
        }

        let statusMatch = true;
        const shipmentStatus = shipment.status.toLowerCase();
        switch (service.shipments.status.toLowerCase()) {
            case 'active':
                statusMatch = shipmentStatus === 'pending' || shipmentStatus === 'active' || shipmentStatus === 'overdue';
                break;
            case 'pending':
                statusMatch = shipmentStatus === 'pending';
                break;
            case 'in-transit':
                statusMatch = shipmentStatus === 'active';
                break;
            case 'overdue':
                statusMatch = shipmentStatus === 'overdue';
                break;
            case 'expired':
                statusMatch = shipmentStatus === 'expired';
                break;
            case 'cancelled':
                statusMatch = shipmentStatus === 'cancelled';
                break;
            case 'complete':
                statusMatch = shipmentStatus === 'complete';
                break;
            default:
                statusMatch = true;
        }

        return isTextMatch && isOriginMatch && isDestinationMatch && statusMatch;
    };

    const isType = report => service.filterByDeviceType === 'All' || report.type === service.filterByDeviceType;

    const isAbove = report => service.range.filterOption === 'above' && report[service.range.property] > service.range.above;

    const isBelow = report => service.range.filterOption === 'below' && report[service.range.property] < service.range.below;

    const isBetween = report => service.range.filterOption === 'between' && (report[service.range.property] >= service.range.from && report[service.range.property] <= service.range.to);

    const isOutside = report => service.range.filterOption === 'outside' && !(report[service.range.property] >= service.range.from && report[service.range.property] <= service.range.to);

    const filter = report => {
        const hasShipment = !!report.shipment;
        const hasType = !!report.type;

        const isSeverityMatch = service.showWarning && report.severity === 'warning' ||
            service.showInfo && report.severity === 'info' ||
            service.showOk && report.severity === 'ok';

        let isTextMatch = true;
        if (service.searchText) {
            const lowerCaseSearchText = service.searchText.toLowerCase();
            if (hasShipment) {
                isTextMatch = (
                    report.shipment.deviceTagId.toLowerCase().indexOf(lowerCaseSearchText) > -1 ||
                    report.shipment.referenceNumber.toLowerCase().indexOf(lowerCaseSearchText) > -1 ||
                    _.findIndex(report.alarms, function (alarm) {
                        return alarm.groupName.toLowerCase().indexOf(lowerCaseSearchText) > -1;
                    }) > -1
                );
            } else {
                isTextMatch = (
                    report.deviceTagId.toLowerCase().indexOf(lowerCaseSearchText) > -1 ||
                    report.deviceName.toLowerCase().indexOf(lowerCaseSearchText) > -1 ||
                    _.findIndex(report.alarms, function (alarm) {
                        return alarm.toLowerCase().indexOf(lowerCaseSearchText) > -1;
                    }) > -1
                );
            }
        }

        const isRangeMatch = service.range.filterOption === 'none' ? true : isAbove(report) || isBelow(report) || isBetween(report) || isOutside(report);

        const isDeviceType = !hasShipment && hasType ? isType(report) : true;

        let statusMatch = true;
        if (hasShipment) {
            const shipmentStatus = report.shipment.status.toLowerCase();
            switch (service.shipments.status.toLowerCase()) {
                case 'active':
                    statusMatch = /*shipmentStatus === 'pending' ||*/ shipmentStatus === 'active' || shipmentStatus === 'overdue';
                    break;
                case 'pending':
                    statusMatch = shipmentStatus === 'pending';
                    break;
                case 'in-transit':
                    statusMatch = shipmentStatus === 'active';
                    break;
                case 'overdue':
                    statusMatch = shipmentStatus === 'overdue';
                    break;
                case 'expired':
                    statusMatch = shipmentStatus === 'expired';
                    break;
                case 'cancelled':
                    statusMatch = shipmentStatus === 'cancelled';
                    break;
                case 'complete':
                    statusMatch = shipmentStatus === 'complete';
                    break;
                default:
                    statusMatch = true;
            }
        }

        return isTextMatch && isSeverityMatch && isRangeMatch && isDeviceType && statusMatch;
    };

    const filterBy = (propertyName, property, min, max, suffix) => {
        service.filterByPropertyName = `${propertyName} (${suffix.trim()})`;
        service.range.property = property;
        service.range.min = min;
        service.range.max = max;
        service.range.above = service.range.min;
        service.range.below = service.range.max;
        service.range.from = service.range.min;
        service.range.to = service.range.max;
        service.range.suffix = suffix;
    }

    const filterByBattery = () => filterBy(`${batteryLabel}`, 'batteryPercent', 0, 100, '%');

    const filterByIceTempC = () => filterBy(`Ice Temp`, 'accessoryTemperatureC', 0, 50, '\xB0C');

    const filterByIceTempF = () => filterBy(`Ice Temp`, 'accessoryTemperatureF', 0, 50, '\xB0F');

    const filterByTrapTempC = () => filterBy(`Trap Temp`, 'accessoryTrapTemperatureC', 0, 50, '\xB0C');

    const filterByTrapTempF = () => filterBy(`Trap Temp`, 'accessoryTrapTemperatureF', 0, 50, '\xB0C');

    const filterByRenalResistance = () => filterBy(`Renal Resistance`, 'accessoryResistance', 0, 3, 'mmHg / ml/min');

    const filterByFlowRate = () => filterBy(`Flow Rate`, 'accessoryFlowRate', 0, 240, 'ml/min');

    const filterByDiastolicPressure = () => filterBy(`Diastolic Pressure`, 'accessoryDiastolicPressure', 0, 65, 'mmHg');

    const filterBySystolicPressure = () => filterBy(`Systolic Pressure`, 'accessorySystolicPressure', 0, 65, 'mmHg');

    const filterByLifePortBattery = () => filterBy(`LifePort Battery`, 'accessoryBattery', 0, 100, '%');

    const filterByHumidity = () => filterBy(`Humidity`, 'humidity', 0, 100, '%');

    const filterByTemperatureC = () => filterBy(`Temperature`, 'temperatureC', -255, 255, '\xB0C');

    const filterByTemperatureF = () => filterBy(`Temperature`, 'temperatureF', -255, 255, '\xB0F');

    const filterByTempProbe1C = () => filterBy(`Temp. Probe  1`, 'temperatureProbe1C', -255, 255, '\xB0C');

    const filterByTempProbe1F = () => filterBy(`Temp. Probe 1`, 'temperatureProbe1F', -255, 255, '\xB0F');

    const filterByTempProbe2C = () => filterBy(`Temp. Probe 2`, 'temperatureProbe2C', -255, 255, '\xB0C');

    const filterByTempProbe2F = () => filterBy(`Temp. Probe 2`, 'temperatureProbe2F', -255, 255, '\xB0F');

    const filterByDewPointC = () => filterBy(`DewPoint`, 'dewPointC', -255, 255, '\xB0C');

    const filterByDewPointF = () => filterBy(`DewPoint`, 'dewPointF', -255, 255, '\xB0F');

    const filterByPressure = () => filterBy(`Pressure`, 'pressure', 30, 110, 'kPa');

    const filterByLight = () => filterBy(`Light`, 'light', 30, 188000 /* TODO: how to handle big number? */, 'lux');

    const filterByShock = () => filterBy(`Shock`, 'shockMagnitude', 0, 25, ' g');

    const filterByTilt = () => filterBy(`Tilt`, 'tiltMagnitude', 0, 25, '\xB0');

    const save = () => {
        const newFilterSettings = {
            searchText: service.searchText,
            shipments: {
                status: service.shipments.status,
                origin: service.shipments.origin,
                destination: service.shipments.destination
            },
            showOk: service.showOk,
            showInfo: service.showInfo,
            showWarning: service.showWarning,
            filterByPropertyName: service.filterByPropertyName,
            range: service.range
        };
        localStorageService.set(localStorageKey, newFilterSettings);
    };

    const setFilterByDeviceType = deviceType => {
        $rootScope.loading = true;
        _.defer(function () {
            service.filterByDeviceType = deviceType;
            $rootScope.loading = false;
            $rootScope.$apply();
        });
    };

    const setFilterOption = option => {
        $rootScope.loading = true;
        _.defer(function () {
            service.range.filterOption = option;
            $rootScope.loading = false;
            $rootScope.$apply();
        });
    };

    const defaultFilterProperty = $rootScope.isOrs ? 'accessoryResistance' : `batteryPercent`;
    const defaultFilterUnit = $rootScope.isOrs ? 'mmHg / ml/min' : `%`;
    const defaultFilterName = $rootScope.isOrs ? `Renal Resistance (${defaultFilterUnit})` : `${batteryLabel} (${defaultFilterUnit})`;

    const service = {
        searchText: filterSettings ? filterSettings.searchText : null,
        shipments: {
            filter: filterShipments,
            status: filterSettings ? filterSettings.shipments.status : 'All',
            origin: filterSettings ? filterSettings.shipments.origin : null,
            destination: filterSettings ? filterSettings.shipments.destination : null
        },
        showOk: filterSettings ? filterSettings.showOk : true,
        showInfo: filterSettings ? filterSettings.showInfo : true,
        showWarning: filterSettings ? filterSettings.showWarning : true,
        filteredReports: [],
        isAbove: isAbove,
        isBelow: isBelow,
        isBetween: isBetween,
        isOutside: isOutside,
        isType: isType,
        batteryLabel: batteryLabel,
        filterByPropertyName: filterSettings ? filterSettings.filterByPropertyName : defaultFilterName,
        range: {
            property: filterSettings ? filterSettings.range.property : defaultFilterProperty,
            min: filterSettings ? filterSettings.range.min : 0,
            max: filterSettings ? filterSettings.range.max : 100,
            above: filterSettings ? filterSettings.range.above : 0,
            below: filterSettings ? filterSettings.range.below : 100,
            from: filterSettings ? filterSettings.range.from : 0,
            to: filterSettings ? filterSettings.range.to : 100,
            filterOption: filterSettings ? filterSettings.range.filterOption : 'none',
            suffix: filterSettings ? filterSettings.range.suffix : defaultFilterUnit
        },
        filter: filter,
        filterByBattery: filterByBattery,
        filterByDewPointC: filterByDewPointC,
        filterByDewPointF: filterByDewPointF,
        filterByTemperatureC: filterByTemperatureC,
        filterByTemperatureF: filterByTemperatureF,
        filterByTempProbe1C: filterByTempProbe1C,
        filterByTempProbe1F: filterByTempProbe1F,
        filterByTempProbe2C: filterByTempProbe2C,
        filterByTempProbe2F: filterByTempProbe2F,
        filterByLight: filterByLight,
        filterByHumidity: filterByHumidity,
        filterByShock: filterByShock,
        filterByPressure: filterByPressure,
        filterByTilt: filterByTilt,
        filterByIceTempC: filterByIceTempC,
        filterByIceTempF: filterByIceTempF,
        filterByTrapTempC:filterByTrapTempC,
        filterByTrapTempF:filterByTrapTempF,
        filterByRenalResistance: filterByRenalResistance,
        filterByFlowRate: filterByFlowRate,
        filterByDiastolicPressure: filterByDiastolicPressure,
        filterBySystolicPressure: filterBySystolicPressure,
        filterByLifePortBattery: filterByLifePortBattery,
        filterByDeviceType: 'Sentry',
        save: save,
        setFilterByDeviceType: setFilterByDeviceType,
        setFilterOption: setFilterOption,
    };

    return service;
}
