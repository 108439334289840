import angular from "angular";

angular
    .module('api-common')
    .factory('SentryReportColumnSelectorService', SentryReportColumnSelectorService);

SentryReportColumnSelectorService.$inject = ['$rootScope', 'SentinelUiSession', 'localStorageService'];

function SentryReportColumnSelectorService($rootScope, SentinelUiSession, localStorageService) {
    const _localStorageKey = 'sentryReportColumnSelectorService';

    const isOrs = $rootScope.isOrs;
    const accountIsTopParent = SentinelUiSession.user.accountIsTopParent;

    const oaDataDefault = !isOrs || (isOrs && accountIsTopParent);
    const orsDataDefault = isOrs;
    const showDegreesF = !isOrs;

    const service = {
        age: false,
        alarms: false,
        activatedAlarms: false,
        battery: false,
        communicationType: false,
        humidity: false,
        isButtonPushed: false,
        isMotionDetected: false,
        isStoreAndForward: false,
        latLng: false,
        light: false,
        locationMethod: false,
        messageTimeStamp: false,
        messageRefNumber: false,
        pressure: false,
        reportGuid: false,
        reportId: false,
        geocodingCity: false,
        geocodingState: false,
        geocodingCountry: false,
        hasAlarms: false,
        reportInterval: false,
        serverTimeStamp: false,
        shockMagnitude: false,
        tiltMagnitude: false,
        shockElapsedTime: false,
        shockXYZ: false,
        tiltXYZ: false,
        signalQuality: false,
        dewPointC: false,
        dewPointF: false,
        extSwitch: false,
        temperatureC: false,
        temperatureF: false,
        temperatureProbe1C: false,
        temperatureProbe1F: false,
        temperatureProbe2C: false,
        temperatureProbe2F: false,
        bleLocation: false,
        countOfSightings: false,
        trackingStartDate: false,
        trackingEndDate: false,
        accessoryError: false,
        accessoryBattery: false,
        accessoryDiastolicPressure: false,
        accessoryFlowRate: false,
        accessoryResistance: false,
        accessorySystolicPressure: false,
        accessoryTemperatureC: false,
        accessoryTemperatureF: false,
        accessoryTrapTemperatureC: false,
        accessoryTrapTemperatureF: false,
        reset: reset,
        selectNone: selectNone,
        selectAll: selectAll,
        count: 0,
        toggle: toggle
    };
    activate();
    return service;

    /////////////////////////////////////////////

    function activate() {
        const storedSettings = localStorageService.get(_localStorageKey);
        if (!storedSettings) {
            reset();
        } else {
            restore(storedSettings);
        }
    }

    function updateCount() {
        let count = 0;
        _.forEach(service, function (column) {
            if (column === true) {
                count++;
            }
        });

        service.count = count;
    }

    function reset() {
        service.age = true;
        service.alarms = !isOrs;
        service.activatedAlarms = !isOrs;
        service.battery = true;
        service.communicationType = false;
        service.humidity = oaDataDefault;
        service.isButtonPushed = oaDataDefault;
        service.isMotionDetected = oaDataDefault;
        service.isStoreAndForward = oaDataDefault && !isOrs;
        service.latLng = false;
        service.light = oaDataDefault;
        service.locationMethod = false;
        service.messageTimeStamp = true;
        service.messageRefNumber = oaDataDefault;
        service.pressure = oaDataDefault;
        service.reportGuid = false;
        service.reportId = false;
        service.geocodingCity = true;
        service.geocodingState = true;
        service.geocodingCountry = true;
        service.hasAlarms = !isOrs;
        service.reportInterval = true;
        service.serverTimeStamp = !isOrs;
        service.shockMagnitude = oaDataDefault;
        service.shockElapsedTime = oaDataDefault;
        service.tiltMagnitude = oaDataDefault;
        service.shockXYZ = oaDataDefault;
        service.tiltXYZ = oaDataDefault;
        service.signalQuality = oaDataDefault;
        service.dewPointC = oaDataDefault;
        service.dewPointF = oaDataDefault && showDegreesF;
        service.extSwitch = oaDataDefault && !isOrs;
        service.temperatureC = oaDataDefault;
        service.temperatureF = oaDataDefault && showDegreesF;
        service.temperatureProbe1C = oaDataDefault && !isOrs;
        service.temperatureProbe1F = oaDataDefault && showDegreesF;
        service.temperatureProbe2C = oaDataDefault && !isOrs;
        service.temperatureProbe2F = oaDataDefault && showDegreesF;
        service.bleLocation = true;
        service.countOfSightings = !isOrs;
        service.trackingStartDate = true;
        service.trackingEndDate = true;
        service.accessoryError = orsDataDefault;
        service.accessoryBattery = orsDataDefault;
        service.accessoryDiastolicPressure = orsDataDefault;
        service.accessoryFlowRate = orsDataDefault;
        service.accessoryResistance = orsDataDefault;
        service.accessorySystolicPressure = orsDataDefault;
        service.accessoryTemperatureC = orsDataDefault;
        service.accessoryTemperatureF = orsDataDefault && showDegreesF;
        service.accessoryTrapTemperatureC = orsDataDefault;
        service.accessoryTrapTemperatureF = orsDataDefault && showDegreesF;
        updateCount();
        localStorageService.set(_localStorageKey, null);
    }

    function restore(settings) {
        service.age = settings.age;
        service.alarms = settings.alarms;
        service.activatedAlarms = settings.activatedAlarms;
        service.battery = settings.battery;
        service.communicationType = settings.communicationType;
        service.humidity = settings.humidity;
        service.isButtonPushed = settings.isButtonPushed;
        service.isMotionDetected = settings.isMotionDetected;
        service.isStoreAndForward = settings.isStoreAndForward;
        service.latLng = settings.latLng;
        service.light = settings.light;
        service.locationMethod = settings.locationMethod;
        service.messageTimeStamp = settings.messageTimeStamp;
        service.messageRefNumber = settings.messageRefNumber;
        service.pressure = settings.pressure;
        service.reportGuid = settings.reportGuid;
        service.reportId = settings.reportId;
        service.geocodingCity = settings.geocodingCity;
        service.geocodingState = settings.geocodingState;
        service.geocodingCountry = settings.geocodingCountry;
        service.hasAlarms = settings.hasAlarms;
        service.reportInterval = settings.reportInterval;
        service.serverTimeStamp = settings.serverTimeStamp;
        service.shockMagnitude = settings.shockMagnitude;
        service.shockElapsedTime = settings.shockElapsedTime;
        service.shockXYZ = settings.shockXYZ;
        service.tiltXYZ = settings.tiltXYZ;
        service.signalQuality = settings.signalQuality;
        service.dewPointC = settings.dewPointC;
        service.dewPointF = settings.dewPointF;
        service.extSwitch = settings.extSwitch;
        service.temperatureC = settings.temperatureC;
        service.temperatureF = settings.temperatureF;
        service.temperatureProbe1C = settings.temperatureProbe1C;
        service.temperatureProbe1F = settings.temperatureProbe1F;
        service.temperatureProbe2C = settings.temperatureProbe2C;
        service.temperatureProbe2F = settings.temperatureProbe2F;
        service.bleLocation = settings.bleLocation;
        service.countOfSightings = settings.countOfSightings;
        service.trackingStartDate = settings.trackingStartDate;
        service.trackingEndDate = settings.trackingEndDate;
        service.accessoryError = settings.accessoryError;
        service.accessoryBattery = settings.accessoryBattery;
        service.accessoryDiastolicPressure = settings.accessoryDiastolicPressure;
        service.accessoryFlowRate = settings.accessoryFlowRate;
        service.accessoryResistance = settings.accessoryResistance;
        service.accessorySystolicPressure = settings.accessorySystolicPressure;
        service.accessoryTemperatureC = settings.accessoryTemperatureC;
        service.accessoryTemperatureF = settings.accessoryTemperatureF;
        service.accessoryTrapTemperatureC = settings.accessoryTrapTemperatureC;
        service.accessoryTrapTemperatureF = settings.accessoryTrapTemperatureF;
        updateCount();
    }

    function selectAll() {
        service.age = true;
        service.alarms = !isOrs;
        service.activatedAlarms = !isOrs;
        service.battery = true;
        service.communicationType = true;
        service.humidity = true;
        service.isButtonPushed = true;
        service.isMotionDetected = true;
        service.isStoreAndForward = !isOrs;
        service.latLng = true;
        service.light = true;
        service.locationMethod = true;
        service.messageTimeStamp = true;
        service.messageRefNumber = true;
        service.pressure = true;
        service.reportGuid = true;
        service.reportId = true;
        service.geocodingCity = true;
        service.geocodingState = true;
        service.geocodingCountry = true;
        service.hasAlarms = !isOrs;
        service.reportInterval = true;
        service.serverTimeStamp = !isOrs;
        service.shockMagnitude = true;
        service.shockElapsedTime = true;
        service.shockXYZ = true;
        service.tiltXYZ = true;
        service.signalQuality = true;
        service.dewPointC = true;
        service.dewPointF = showDegreesF;
        service.extSwitch = !isOrs;
        service.temperatureC = true;
        service.temperatureF = showDegreesF;
        service.temperatureProbe1C = !isOrs;
        service.temperatureProbe1F = showDegreesF;
        service.temperatureProbe2C = !isOrs;
        service.temperatureProbe2F = showDegreesF;
        service.bleLocation = true;
        service.countOfSightings = !isOrs;
        service.trackingStartDate = true;
        service.trackingEndDate = true;
        service.accessoryError = orsDataDefault;
        service.accessoryBattery = orsDataDefault;
        service.accessoryDiastolicPressure = orsDataDefault;
        service.accessoryFlowRate = orsDataDefault;
        service.accessoryResistance = orsDataDefault;
        service.accessorySystolicPressure = orsDataDefault;
        service.accessoryTemperatureC = orsDataDefault;
        service.accessoryTemperatureF = orsDataDefault;
        service.accessoryTrapTemperatureC = orsDataDefault;
        service.accessoryTrapTemperatureF = orsDataDefault;
        updateCount();
        store();
    }

    function selectNone() {
        service.age = false;
        service.alarms = false;
        service.activatedAlarms = false;
        service.battery = false;
        service.communicationType = false;
        service.humidity = false;
        service.isButtonPushed = false;
        service.isMotionDetected = false;
        service.isStoreAndForward = false;
        service.latLng = false;
        service.light = false;
        service.locationMethod = false;
        service.messageTimeStamp = false;
        service.messageRefNumber = false;
        service.pressure = false;
        service.reportGuid = false;
        service.reportId = false;
        service.geocodingCity = false;
        service.geocodingState = false;
        service.geocodingCountry = false;
        service.hasAlarms = false;
        service.reportInterval = false;
        service.serverTimeStamp = false;
        service.shockMagnitude = false;
        service.shockElapsedTime = false;
        service.shockXYZ = false;
        service.tiltXYZ = false;
        service.signalQuality = false;
        service.dewPointC = false;
        service.dewPointF = false;
        service.extSwitch = false;
        service.temperatureC = false;
        service.temperatureF = false;
        service.temperatureProbe1C = false;
        service.temperatureProbe1F = false;
        service.temperatureProbe2C = false;
        service.temperatureProbe2F = false;
        service.bleLocation = false;
        service.countOfSightings = false;
        service.trackingStartDate = false;
        service.trackingEndDate = false;
        service.accessoryError = false;
        service.accessoryBattery = false;
        service.accessoryDiastolicPressure = false;
        service.accessoryFlowRate = false;
        service.accessoryResistance = false;
        service.accessorySystolicPressure = false;
        service.accessoryTemperatureC = false;
        service.accessoryTemperatureF = false;
        service.accessoryTrapTemperatureC = false;
        service.accessoryTrapTemperatureF = false;
        updateCount();
        store();
    }

    function store() {
        localStorageService.set(_localStorageKey, {
            age: service.age,
            alarms: service.alarms,
            activatedAlarms: service.activatedAlarms,
            battery: service.battery,
            communicationType: service.communicationType,
            humidity: service.humidity,
            isButtonPushed: service.isButtonPushed,
            isMotionDetected: service.isMotionDetected,
            isStoreAndForward: service.isStoreAndForward,
            latLng: service.latLng,
            light: service.light,
            locationMethod: service.locationMethod,
            messageTimeStamp: service.messageTimeStamp,
            messageRefNumber: service.messageRefNumber,
            pressure: service.pressure,
            reportGuid: service.reportGuid,
            reportId: service.reportId,
            geocodingCity: service.geocodingCity,
            geocodingState: service.geocodingState,
            geocodingCountry: service.geocodingCountry,
            hasAlarms: service.hasAlarms,
            reportInterval: service.reportInterval,
            serverTimeStamp: service.serverTimeStamp,
            shockMagnitude: service.shockMagnitude,
            shockElapsedTime: service.shockElapsedTime,
            shockXYZ: service.shockXYZ,
            tiltXYZ: service.tiltXYZ,
            signalQuality: service.signalQuality,
            dewPointC: service.dewPointC,
            dewPointF: service.dewPointF,
            extSwitch: service.extSwitch,
            temperatureC: service.temperatureC,
            temperatureF: service.temperatureF,
            temperatureProbe1C: service.temperatureProbe1C,
            temperatureProbe1F: service.temperatureProbe1F,
            temperatureProbe2C: service.temperatureProbe2C,
            temperatureProbe2F: service.temperatureProbe2F,
            bleLocation: service.bleLocation,
            countOfSightings: service.countOfSightings,
            trackingStartDate: service.trackingStartDate,
            trackingEndDate: service.trackingEndDate,
            accessoryError: service.accessoryError,
            accessoryBattery: service.accessoryBattery,
            accessoryDiastolicPressure: service.accessoryDiastolicPressure,
            accessoryFlowRate: service.accessoryFlowRate,
            accessoryResistance: service.accessoryResistance,
            accessorySystolicPressure: service.accessorySystolicPressure,
            accessoryTemperatureC: service.accessoryTemperatureC,
            accessoryTemperatureF: service.accessoryTemperatureF,
            accessoryTrapTemperatureC: service.accessoryTrapTemperatureC,
            accessoryTrapTemperatureF: service.accessoryTrapTemperatureF
        });
    }

    function toggle(column) {
        service[column] = !service[column];
        updateCount();
        store();
    }
}
