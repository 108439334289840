import angular from "angular";
import $ from 'jquery';
import 'bootstrap';

angular
    .module('ui-sentinel.accounts')
    .controller('AccountsListController', AccountsListController);

/////////////

AccountsListController.$inject = ['$rootScope', '$state', 'AccountApiService', 'SentinelUiSession', 'SentryAdminApiService', 'SentryAccountApiService', 'SentinelAccountApiService', 'SentinelAdminApiService'];

function AccountsListController($rootScope, $state, AccountApiService, SentinelUiSession, SentryAdminApiService, SentryAccountApiService, SentinelAccountApiService, SentinelAdminApiService) {
    const vm = {
        list: null,
        account: null,
        newName: null,
        newSubAccountName: null,
        parentAccountTransfer: null,
        subaccounts: null,
        transferSentriesImeiText: null,
        transferSentinelsMacText: null,
        transferMode: null,
        transferTitle: null,
        page: 1,
        roles: ["Gateway", "Warehouse", "Calibration"],
        countOfItems: 0,
        countOfPages: 1,
        itemsPerPage: 500,
        activateInProgress: false,
        rolesAccessAccount: null,
        isOrs: $rootScope.isOrs,
        accountIsTopParent: SentinelUiSession.user.accountIsTopParent,
        setFocus: setFocus,
        activateBegin: activateBegin,
        activateCancel: activateCancel,
        activateSubmit: activateSubmit,
        activeAccount: activeAccount,
        suspendAccount: suspendAccount,
        rolesAccessBegin: rolesAccessBegin,
        rolesAccessCancel: rolesAccessCancel,
        rolesAccessSubmit: rolesAccessSubmit,
        filterRoles: filterRoles,
        transferSentriesBegin: transferSentriesBegin,
        untransferSentriesBegin: untransferSentriesBegin,
        transferSentriesCancel: transferSentriesCancel,
        transferSentriesSubmit: transferSentriesSubmit,
        transferSentinelsBegin: transferSentinelsBegin,
        untransferSentinelsBegin: untransferSentinelsBegin,
        transferSentinelsCancel: transferSentinelsCancel,
        transferSentinelsSubmit: transferSentinelsSubmit,
        addAccountInProgress: false,
        addAccountBegin: addAccountBegin,
        addAccountCancel: addAccountCancel,
        addAccountSubmit: addAccountSubmit,
        suspendInProgress: false,
        suspendBegin: suspendBegin,
        suspendCancel: suspendCancel,
        suspendSubmit: suspendSubmit,
        changeNameInProgress: false,
        changeNameBegin: changeNameBegin,
        changeNameCancel: changeNameCancel,
        changeNameSubmit: changeNameSubmit,
        canCreateAccount: canCreateAccount,
        canCreateSubAccount: canCreateSubAccount,
        canSuspendAccount: canSuspendAccount,
        errorMessage: null,
        activateErrorMessage: null,
        rolesAccessErrorMessage: null,
        addAccountErrorMessage: null,
        changeNameErrorMessage: null,
        transferSentriesErrorMessage: null,
        transferSentinelsErrorMessage: null,
        suspendErrorMessage: null,
        addSubAccountInProgress: false,
        addSubAccountBegin: addSubAccountBegin,
        addSubAccountCancel: addSubAccountCancel,
        addSubAccountSubmit: addSubAccountSubmit,
        treeifyAccountList: treeifyAccountList,
        deleteBegin: deleteBegin,
        deleteCancel: deleteCancel,
        deleteSubmit: deleteSubmit,
        isSystemAdmin: isSystemAdmin,
        propertyName: 'name',
        reverse: false,
        sortBy: sortBy,
    };

    const genericErrorMessage = "Unexpected error occurred while getting the logins records";
    const genericErrorTransferMessage = "Unexpected error occurred while Transfer devices records";
    const genericAddAccountErrorMessage = "Unexpected error occurred while adding an account";
    const genericActivateErrorMessage = "Unexpected error occurred while activating an account";
    const genericSuspendErrorMessage = "Unexpected error occurred while suspending an account";
    const genericChangeNameErrorMessage = "Unexpected error occurred while changing the name";
    const genericAddSubAccountErrorMessage = "Unexpected error occurred while adding a subaccount";
    const genericDeleteErrorMessage = "Unexpected error occurred while deleting a subaccount";

    activate();
    return vm;

    function activate() {
        load();
    }

    function load() {
        $rootScope.loading = true;
        $('.modal').modal('hide');
        vm.list = null;
        vm.errorMessage = null;
        vm.activateErrorMessage = null;
        vm.addAccountErrorMessage = null;
        vm.changeNameErrorMessage = null;
        vm.suspendErrorMessage = null;
        const promise = AccountApiService.listAccounts().$promise;
        promise.then(
            function (result) {
                vm.list = treeifyAccountList(result);
                console.log("List of accounts", vm.list);
                suspendCancel();
                changeNameCancel();
                activateCancel();
                addAccountCancel();
            },
            function (error) {
                console.log(error);
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function activateBegin(account) {
        vm.activateInProgress = true;
        vm.account = account;
        vm.activateErrorMessage = null;
    }

    function activateCancel() {
        vm.activateErrorMessage = null;
        vm.activateInProgress = false;
        vm.account = null;
    }

    function activateSubmit() {
        $rootScope.loading = true;
        const promise = AccountApiService.activateAccount(vm.account).$promise;
        promise.then(
            function (result) {
                load();

            },
            function (error) {
                console.log(error);
                vm.activateErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericActivateErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function activeAccount() {
        $rootScope.loading = true;
        const promise = AccountApiService.activateAccount(vm.account).$promise;
        promise.then(
            function (result) {
                vm.account.status = 'active';
            },
            function (error) {
                console.log(error);
                vm.activateErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericActivateErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function rolesAccessBegin(account) {
        vm.account = account;
        vm.rolesAccessAccount = angular.copy(account);

        vm.rolesAccessErrorMessage = null;
    }

    function rolesAccessCancel() {
        vm.rolesAccessErrorMessage = null;
        vm.rolesAccessAccount = null;
    }

    function rolesAccessSubmit() {
        $rootScope.loading = true;
        const promise = AccountApiService.changeaccesslevel(vm.account, vm.rolesAccessAccount.accessLevel).$promise;
        promise.then(
            function (result) {
                load();
            },
            function (error) {
                console.log(error);
                vm.rolesAccessErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericActivateErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function filterRoles(role) {
        return role !== "Gateway";
    }

    function transferSentriesBegin(account) {
        vm.subaccounts = account.children;
        vm.parentAccountTransfer = account;
        vm.transferSentriesErrorMessage = null;
        vm.transferSentriesImeiText = null;
        vm.transferMode = "add";
        vm.transferTitle = "Transfer Sentries to subaccount";
    }

    function untransferSentriesBegin(account) {
        vm.subaccounts = account.children;
        vm.parentAccountTransfer = account;
        vm.transferSentriesErrorMessage = null;
        vm.transferSentriesImeiText = null;
        vm.transferMode = "remove";
        vm.transferTitle = "Remove Sentries on subaccount";
    }

    function transferSentriesCancel() {
        vm.subaccounts = null;
        vm.parentAccountTransfer = null;
        vm.transferSentriesErrorMessage = null;
        vm.transferSentriesImeiText = null;
        vm.transferMode = null;
        vm.transferTitle = null;
    }

    function isSystemAdmin() {
        return SentinelUiSession.user && SentinelUiSession.user.isSystemAdmin;
    }

    function transferSentriesSubmit() {
        if (!vm.transferSentriesSubAccount || !vm.transferSentriesImeiText) {
            return;
        }

        console.log("Parent Account", vm.parentAccountTransfer);
        console.log("SubAccount", vm.transferSentriesSubAccount);
        console.log("Imeis", vm.transferSentriesImeiText);

        $rootScope.loading = true;
        vm.transferSentriesErrorMessage = null;

        const imeiList = _.split(vm.transferSentriesImeiText, '\n');

        if (vm.transferMode === "add") {
            console.log("imeiList", imeiList);
            const promise = isSystemAdmin() ?
                SentryAdminApiService.transferSentries(vm.transferSentriesSubAccount, imeiList).$promise :
                SentryAccountApiService.transferSentries(vm.transferSentriesSubAccount, imeiList).$promise;

            promise.then(
                function (result) {
                    load();
                    transferSentriesCancel();
                },
                function (error) {
                    console.log(error);
                    vm.transferSentriesErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorTransferMessage;
                }
            ).finally(function () {
                $rootScope.loading = false;
            });
        } else if (vm.transferMode === "remove") {
            const promiseRem = isSystemAdmin() ?
                SentryAdminApiService.untransferSentries(vm.transferSentriesSubAccount, imeiList).$promise :
                SentryAccountApiService.untransferSentries(vm.transferSentriesSubAccount, imeiList).$promise;
            promiseRem.then(
                function (result) {
                    load();
                    transferSentriesCancel();
                },
                function (error) {
                    console.log(error);
                    vm.transferSentriesErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorTransferMessage;
                }
            ).finally(function () {
                $rootScope.loading = false;
            });
        }
    }

    function transferSentinelsBegin(account) {
        vm.subaccounts = account.children;
        vm.parentAccountTransfer = account;
        vm.transferSentinelsErrorMessage = null;
        vm.transferSentinelsMacText = null;
        vm.transferMode = "add";
        vm.transferTitle = "Transfer Sentinels to subaccount";
    }

    function untransferSentinelsBegin(account) {
        vm.subaccounts = account.children;
        vm.parentAccountTransfer = account;
        vm.transferSentinelsErrorMessage = null;
        vm.transferSentinelsMacText = null;
        vm.transferMode = "remove";
        vm.transferTitle = "Remove Sentinels on subaccount";
    }

    function transferSentinelsCancel() {
        vm.subaccounts = null;
        vm.parentAccountTransfer = null;
        vm.transferSentinelsErrorMessage = null;
        vm.transferSentinelsMacText = null;
        vm.transferMode = null;
        vm.transferTitle = null;
    }

    function transferSentinelsSubmit() {
        if (!vm.transferSentriesSubAccount || !vm.transferSentinelsMacText) {
            return;
        }

        console.log("Parent Account", vm.parentAccountTransfer);
        console.log("SubAccount", vm.transferSentriesSubAccount);
        console.log("MACs", vm.transferSentinelsMacText);

        $rootScope.loading = true;
        vm.transferSentinelsErrorMessage = null;

        const imeiList = _.split(vm.transferSentinelsMacText, '\n');

        if (vm.transferMode === "add") {
            console.log("imeiList", imeiList);
            const promise = isSystemAdmin() ?
                SentinelAdminApiService.transferSentinels(vm.transferSentriesSubAccount, imeiList).$promise :
                SentinelAccountApiService.transferSentinels(vm.transferSentriesSubAccount, imeiList).$promise;

            promise.then(
                function (result) {
                    load();
                    transferSentriesCancel();
                },
                function (error) {
                    console.log(error);
                    vm.transferSentinelsErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorTransferMessage;
                }
            ).finally(function () {
                $rootScope.loading = false;
            });
        } else if (vm.transferMode === "remove") {
            const promise1 = isSystemAdmin() ?
                SentinelAdminApiService.untransferSentinels(vm.transferSentriesSubAccount, imeiList).$promise :
                SentinelAccountApiService.untransferSentinels(vm.transferSentriesSubAccount, imeiList).$promise;
            promise1.then(
                function (result) {
                    load();
                    transferSentinelsCancel();
                },
                function (error) {
                    console.log(error);
                    vm.transferSentinelsErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericErrorTransferMessage;
                }
            ).finally(function () {
                $rootScope.loading = false;
            });
        }
    }

    function addAccountBegin() {
        vm.addAccountInProgress = true;
        vm.account = null;
        vm.newName = null;
        vm.addAccountErrorMessage = null;
    }

    function addAccountCancel() {
        vm.addAccountErrorMessage = null;
        vm.addAccountInProgress = false;
        vm.account = null;
    }


    function addAccountSubmit() {
        $rootScope.loading = true;
        const promise = AccountApiService.addAccount(vm.newName).$promise;
        promise.then(
            function (result) {
                load();

            },
            function (error) {
                console.log(error);
                vm.addAccountErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericAddAccountErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function addSubAccountBegin(account) {
        vm.addSubAccountErrorMessage = null;
        vm.addSubAccountInProgress = true;
        vm.newSubAccountName = null;
        vm.subaccount = null;
        vm.account = account;
    }

    function addSubAccountCancel() {
        vm.addSubAccountErrorMessage = null;
        vm.addSubAccountInProgress = false;
        vm.subaccount = null;
        vm.account = null;
    }

    function addSubAccountSubmit() {
        $rootScope.loading = true;
        const promise = AccountApiService.addSubAccount(vm.account, vm.newSubAccountName).$promise;
        promise.then(
            function (result) {
                load();

            },
            function (error) {
                console.log(error);
                vm.addSubAccountErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericAddSubAccountErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function suspendBegin(account) {
        vm.suspendInProgress = true;
        vm.account = account;
        vm.suspendErrorMessage = null;
    }

    function suspendCancel() {
        vm.suspendErrorMessage = null;
        vm.suspendInProgress = false;
        vm.account = null;
    }

    function suspendSubmit() {
        $rootScope.loading = true;
        const promise = AccountApiService.suspendAccount(vm.account).$promise;
        promise.then(
            function (result) {
                load();

            },
            function (error) {
                console.log(error);
                vm.suspendErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericSuspendErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function suspendAccount() {
        $rootScope.loading = true;
        const promise = AccountApiService.suspendAccount(vm.account).$promise;
        promise.then(
            function (result) {
                vm.account.status = 'suspended';
            },
            function (error) {
                console.log(error);
                vm.suspendErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericSuspendErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function deleteBegin(account) {
        vm.deleteErrorMessage = null;
        vm.deleteInProgress = true;
        vm.account = account;
    }

    function deleteCancel() {
        vm.deleteErrorMessage = null;
        vm.deleteInProgress = false;
        vm.account = null;
    }

    function deleteSubmit() {
        $rootScope.loading = true;
        const promise = AccountApiService.deleteSubAccount(vm.account).$promise;
        promise.then(
            function (result) {
                load();

            },
            function (error) {
                console.log(error);
                vm.deleteErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericDeleteErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }


    function changeNameBegin(account) {
        console.log("Account to edit", account);
        vm.changeNameInProgress = true;
        vm.account = account;
        vm.newName = account.name;
        vm.changeNameErrorMessage = null;
        document.getElementById('txt-NewName').focus();
    }

    function changeNameCancel() {
        vm.changeNameErrorMessage = null;
        vm.changeNameInProgress = false;
        vm.account = null;
        vm.newName = null;
    }

    function changeNameSubmit() {
        if (!vm.newName) {
            return;
        }
        $rootScope.loading = true;
        const promise = AccountApiService.changeNameForAccount(vm.account, vm.newName).$promise;
        promise.then(
            function (result) {
                //load();
                vm.account.name = vm.newName;
            },
            function (error) {
                console.log(error);
                vm.changeNameErrorMessage = (typeof error.data !== 'undefined' && typeof error.data.message !== 'undefined') ? error.data.message : genericChangeNameErrorMessage;
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function setFocus(account) {
        SentinelUiSession.setFocus(account);
        $state.go('home');
    }

    function canCreateAccount() {
        return isSystemAdmin();
    }

    function canCreateSubAccount() {
        return SentinelUiSession.user && !SentinelUiSession.user.isAccountEditor && !SentinelUiSession.user.isAccountObserver;
    }

    function canSuspendAccount(accountId) {
        return SentinelUiSession.user && accountId !== SentinelUiSession.user.accountId;
    }

    function treeifyAccountList(accountList) {
        const indexed_nodes = {}, tree_roots = [];
        let i = 0;
        for (i = 0; i < accountList.length; i += 1) {
            accountList[i].children = [];
            indexed_nodes[accountList[i].id] = accountList[i];
        }
        for (i = 0; i < accountList.length; i += 1) {
            const parent_id = accountList[i].parentId;
            if (typeof parent_id !== "undefined") {
                if (parent_id === 0 || parent_id === null || !indexed_nodes[parent_id]) {
                    tree_roots.push(accountList[i]);
                } else {
                    accountList[i].parentName = indexed_nodes[parent_id].name;
                    indexed_nodes[parent_id].children.push(accountList[i]);
                }
            }
        }

        return (tree_roots === undefined || tree_roots.length === 0) ? accountList : tree_roots;
    }

    function sortBy(propertyName) {
        vm.reverse = (vm.propertyName === propertyName) ? !vm.reverse : false;
        vm.propertyName = propertyName;
    }
}
