import angular from "angular";

angular
    .module('ui-common')
    .directive("fileUpload", ['$rootScope', '$http', '$compile', function ($rootScope, $http, $compile) {
        return {
            restrict: 'AE',
            scope: {
                accept: '@',
                uploadEventName: "@"
            },
            template: require('./multi-input-file-upload-directive.html'),
            link: function (scope, elem) {
                const jqElem = $(elem);
                scope.previewData = [];

                function previewFile(file) {
                    const reader = new FileReader();
                    const obj = new FormData().append('file', file);

                    reader.onload = function (data) {
                        const src = data.target.result;
                        const size = ((file.size / (1024 * 1024)) > 1) ? (file.size / (1024 * 1024)) + ' mB' : (file.size / 1024) + ' kB';

                        scope.$apply(function () {
                            const data = {
                                'name': file.name,
                                'size': size,
                                'type': file.type,
                                'src': src,
                                'data': obj
                            };

                            scope.previewData.push(data);

                            $rootScope.$emit(scope.uploadEventName, scope.previewData);
                        });
                    };
                    reader.readAsDataURL(file);
                }

                function fileChecker(value) {
                    const accepted = [".csv", ".dat", ".doc", ".docx", ".key", ".keychain", ".log", ".msg", ".odt", ".pages", ".pdf", ".pps", ".ppt", ".pptx", ".rtf", ".sdf", ".tar", ".txt", ".xml", ".xlr", ".xls", ".xlsx", ".wpd", ".wps"];

                    for (let i = 0; i < accepted.length; i++) {
                        if (value.indexOf(accepted[i]) !== -1) {
                            return true;
                        }
                    }

                    return false;
                }

                function uploadFile(e, type) {
                    e.preventDefault();

                    let files = "";

                    if (type === "formControl") {
                        files = e.target.files;
                    } else if (type === "drop") {
                        files = e.originalEvent.dataTransfer.files;
                    }

                    for (let i = 0; i < files.length; i++) {
                        const file = files[i];

                        if (fileChecker(file.name)) {
                            previewFile(file);
                        } else {
                            alert(file.name + " is not supported");
                        }
                    }
                }

                jqElem.find('.fileUpload').bind('change', function (e) {
                    uploadFile(e, 'formControl');
                });

                jqElem.find('.dropzone').bind("click", function () {
                    const compiled = $compile(jqElem.find('.fileUpload'))(scope);
                    $(compiled).trigger('click');
                });

                jqElem.find('.dropzone').bind("dragover", function (e) {
                    e.preventDefault();
                });

                jqElem.find('.dropzone').bind("drop", function (e) {
                    uploadFile(e, 'drop');
                });

                scope.remove = function (data) {
                    const index = scope.previewData.indexOf(data);
                    scope.previewData.splice(index, 1);
                    scope.$emit(scope.uploadEventName, scope.previewData);
                };
            }
        };
    }]).directive("imgUpload", ['$rootScope', '$http', '$compile', function ($rootScope, $http, $compile) {
    return {
        restrict: 'AE',
        scope: {
            accept: '@',
            uploadEventName: "@"
        },
        template: require('./multi-input-img-upload-directive.html'),
        link: function (scope, elem) {
            const jqElem = $(elem);
            scope.previewData = [];

            function previewFile(file) {
                const reader = new FileReader();
                const obj = new FormData().append('file', file);
                reader.onload = function (data) {
                    const src = data.target.result;
                    const size = ((file.size / (1024 * 1024)) > 1) ? (file.size / (1024 * 1024)) + ' mB' : (file.size / 1024) + ' kB';
                    scope.$apply(function () {
                        const data = {
                            'name': file.name, 'size': size, 'type': file.type,
                            'src': src, 'data': obj
                        };
                        scope.previewData.push(data);
                        $rootScope.$emit(scope.uploadEventName, scope.previewData);
                    });
                };
                reader.readAsDataURL(file);
            }

            function uploadFile(e, type) {
                e.preventDefault();
                let files = "";
                if (type === "formControl") {
                    files = e.target.files;
                } else if (type === "drop") {
                    files = e.originalEvent.dataTransfer.files;
                }
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file.type.indexOf("image") !== -1) {
                        previewFile(file);
                    } else {
                        alert(file.name + " is not supported");
                    }
                }
            }

            jqElem.find('.fileUpload').bind('change', function (e) {
                uploadFile(e, 'formControl');
            });

            jqElem.find('.dropzone').bind("click", function () {
                const compiled = $compile(jqElem.find('.fileUpload'))(scope);
                $(compiled).trigger('click');
            });

            jqElem.find('.dropzone').bind("dragover", function (e) {
                e.preventDefault();
            });

            jqElem.find('.dropzone').bind("drop", function (e) {
                uploadFile(e, 'drop');
            });

            scope.remove = function (data) {
                const index = scope.previewData.indexOf(data);
                scope.previewData.splice(index, 1);
                scope.$emit(scope.uploadEventName, scope.previewData);
            };
        }
    };
}]);
