import angular from "angular";

// date-formatter
// hide-utc = hide the "UTC" in the output
const dateFormatterDirective = (DateFormatService) => {
    const link = (scope, element, attrs) => {

        const update = (v, a) => scope.dt = DateFormatService.format(v, a);

        update(scope.value, attrs);

        scope.$watch(
            () => attrs,
            newVal => update(scope.value, newVal),
            true
        );

        scope.$watch(
            () => scope.value,
            newVal => update(newVal, attrs),
            true
        );
    }

    return {
        restrict: 'A',
        scope: { value: '@' },
        template: '{{ dt }}',
        link
    };
}

angular.module('ui-common').directive('dateFormatter', ['DateFormatService', dateFormatterDirective]);
