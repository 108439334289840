import angular from "angular";
import moment from "moment/moment";
import debounce from "lodash/debounce";

angular
    .module('ui-common')
    .directive('datePicker', DatePickerDirective);

const yyyymd = new RegExp('^\\d{4}-\\d{1,2}-\\d{1,2}$');
const anyDate = new RegExp('^((\\d{4})|\\d{2})-\\d{1,2}-\\d{1,2}|\\d{1,2}-\\d{1,2}-((\\d{4})|\\d{2})$');

function DatePickerDirective() {
    return {
        require: '?ngModel',
        restrict: 'AE',
        scope: {
            ngModel: '=',
        },
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel) {
                return;
            }
            const jqElem = $(elem);

            jqElem.css('position', 'relative');
            jqElem.addClass('date-box');
            if (jqElem.width() < 160) {
                jqElem.addClass('date-box-thin');
            }

            jqElem.datepicker({
                dateFormat: 'yy-mm-dd',
                onSelect: (selectedDate) => {
                    // selectedDate is yyyy-mm-dd
                    // convert to mm/dd/yyyy (or whatever the local format is) as this is the expected
                    // format in all models
                    const modelFormatted = moment(selectedDate, 'YYYY-MM-DD').format('L');
                    ngModel.$setViewValue(modelFormatted);
                },
                beforeShow: function () {
                    setTimeout(function () {
                        $('.ui-datepicker').css('z-index', 99999999999999);
                    }, 0);
                },
            });

            let isFirstChange = true;

            const processInput = () => {
                if (isFirstChange) {
                    isFirstChange = false;
                }

                let maybeDate = scope.ngModel.replace(/\//g, '-');

                // if it doesn't look like a date, they are probably typing
                if (!maybeDate.match(anyDate)) {
                    jqElem.val(scope.ngModel);
                    return;
                }

                const momentDate = maybeDate.match(yyyymd)
                    ? moment(maybeDate, "YYYY-MM-DD")
                    : moment(scope.ngModel, 'L')

                if (!momentDate.isValid()) {
                    jqElem.val(scope.ngModel);
                } else {
                    jqElem.blur();
                    const pickerFormat = momentDate.format('YYYY-MM-DD');
                    if (jqElem.val() !== pickerFormat) {
                        jqElem.val(pickerFormat);
                    }

                    const localModel = momentDate.format('L');
                    if (localModel !== scope.ngModel) {
                        ngModel.$setViewValue(localModel);
                    }
                    $('.ui-datepicker').hide();
                }
            };

            const debouncedProcessInput = debounce(processInput, 1000);

            scope.$watch('ngModel', () => {
                if (isFirstChange) {
                    processInput();
                } else {
                    debouncedProcessInput();
                }
            });
        }
    };
}
