import angular from 'angular';
import toastr from 'toastr';

angular
    .module('api-common')
    .factory(
        'ShipmentTrackingUrlBuilderService',
        ['$rootScope', 'SentinelUiSession', 'ClipboardCopyService',
            ($rootScope, SentinelUiSession, ClipboardCopyService) => {

                const buildTrackingUrl = async (shipment) => {
                    try {
                        const trackUrl = `${$rootScope.trackingUrl}${encodeURIComponent(shipment.referenceNumber).replace(/\(/g, '%28').replace(/\)/g, '%29')}`;
                        console.log(`Tracking Url: ${trackUrl}`);
                        return trackUrl;
                    } catch (err) {
                        console.log(`Failed to build shipment tracking url`);
                    }
                };

                const copyShipmentTrackingPublicLink = async (shipment) => {
                    if (shipment) {
                        console.log(`Shipment for TrackingURL: ${shipment}`)
                        const trackUrl = await buildTrackingUrl(shipment);
                        const success = await ClipboardCopyService.copyToClipboard(trackUrl);
                        toastr.options = {
                            closeButton: false,
                            debug: false,
                            newestOnTop: true,
                            progressBar: false,
                            positionClass: "toast-top-center",
                            preventDuplicates: true,
                            onclick: null,
                            showDuration: "700",
                            hideDuration: "700",
                            timeOut: "1000",
                            extendedTimeOut: "1000",
                            showEasing: "swing",
                            hideEasing: "linear",
                            showMethod: "fadeIn",
                            hideMethod: "fadeOut"
                        }
                        if (success) {
                            toastr.success('Tracking Page Link Copied!')
                        } else {
                            toastr.error('Error: Tracking Page Link Not Copied.')
                        }
                    } else (
                        console.log('Copy Tracking Url function given a null shipment')
                    )
                };

                const openShipmentTrackingPageAsNewTab = async (shipment) => {
                    if (shipment) {
                        console.log(`Shipment for TrackingURL: ${shipment}`)
                        const trackUrl = await buildTrackingUrl(shipment);
                        window.open(trackUrl, '_blank');
                    }
                }

                return {
                    buildTrackingUrl,
                    copyShipmentTrackingPublicLink,
                    openShipmentTrackingPageAsNewTab
                };
            }
        ]
    );
