import angular from "angular";
import orderBy from "lodash/orderBy";

const looksLikeEmail = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');

const insertOptionSorted = (jqElem, emailAddress) => {
    jqElem.append(`<option value="${emailAddress}">${emailAddress} (custom entry)</option>`);
    const sortedOptions = orderBy(jqElem.find('option'), [o => o.value.toLowerCase()]);
    jqElem.empty().append(sortedOptions);
}

// contact-picker
angular
    .module('ui-common')
    .directive('contactPicker', () => ({
        require: '?ngModel',
        restrict: 'E',
        replace: true,
        template: '<select multiple style="min-width:500px;"></select>',
        scope: {
            ngModel: '=?',
            contacts: '='
        },
        link: function ($scope, elem, attrs) {
            const jqElem = $(elem);
            let emailsInList = [];
            const customValues = [];

            // Initialize the chosen box
            jqElem.chosen({
                width: attrs.width || '100%',
                include_group_label_in_selected: false,
                search_contains: true,
                no_results_text: 'No results.',
                placeholder_text_multiple: 'Choose contacts'
            }).change(() => {
                $scope.ngModel = jqElem.val().filter(c => !!c);
                $scope.$apply();
            }).on('chosen:no_results', function(evt, params) {
                const emailAddress = params.chosen.search_results[0].innerText.replace('No results.', '').replace('Add', '').trim();
                if (emailAddress && emailAddress.match(looksLikeEmail)) {
                    $('.no-results').append('<button id="addContactButton" class="btn btn-default">Add</button>');
                    $('#addContactButton').click(() => {
                        customValues.push(emailAddress);
                        insertOptionSorted(jqElem, emailAddress);
                        const val = modelToSelection($scope.ngModel);
                        val.push(emailAddress);
                        $scope.ngModel = val;
                        $scope.$apply();
                        jqElem.val(val);
                        jqElem.trigger('chosen:updated');
                    });
                    $('.chosen-search-input').on('keydown', function(e){
                        if (e.which === 13) {
                            $('#addContactButton').click();
                        }
                    });
                }
            });

            const modelToSelection = (model) => {
                if (!model) {
                    return [];
                }
                const selected = Array.isArray(model)
                    ? model
                    : model.replace(';', ',').split(',').map(e => e.trim());
                // remove emails that are not in the list, but same them because on edit
                // the model is set before the contacts are loaded.
                const missing = selected.filter(e => !emailsInList.includes(e));

                for (const email of missing) {
                    if (!customValues.includes(email)) {
                        customValues.push(email);
                        insertOptionSorted(jqElem, email);
                    }
                }

                return selected;
            }

            // Update the box if ngModel changes
            $scope.$watch('ngModel', function () {
                const val = modelToSelection($scope.ngModel);
                jqElem.val(val);
                jqElem.trigger('chosen:updated')
            })

            $scope.$watch('contacts', function (newValue) {
                const selected = jqElem.val();
                if (selected.length === 0 && customValues.length > 0) {
                    // ngModel loaded first, that's the selection
                    for (const value of customValues) {
                        selected.push(value);
                    }
                }
                jqElem.empty();
                emailsInList = [];
                if (newValue.length) {
                    // add the contacts as options
                    const custom = customValues
                        .filter(customValue => !newValue.find(nv => nv.emailAddress === customValue))
                        .map(customValue => {
                        return {
                            emailAddress: customValue,
                            firstname: 'custom',
                            lastname: 'entry'
                        }

                    })
                    const allEntries = newValue.concat(custom);
                    const sorted = orderBy(allEntries, [entry => entry.emailAddress.toLowerCase()]);
                    for (const contact of sorted) {
                        emailsInList.push(contact.emailAddress);
                        jqElem.append(`<option value="${contact.emailAddress}">${contact.emailAddress} (${contact.firstname} ${contact.lastname})</option>`);
                    }
                    // update the selection
                    const newSelected = [];
                    for (const email of selected) {
                        if (emailsInList.includes(email)) {
                            newSelected.push(email);
                        }
                    }
                    if (newSelected.length) {
                        jqElem.val(newSelected);
                    }
                }
                jqElem.trigger('chosen:updated');
            })
        }
    }));
