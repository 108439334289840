import angular from "angular";
import moment from "moment/moment";

angular
    .module('ui-sentinel.shipments.latestShipmentTracking')
    .factory('LatestShipmentTrackingReportsService', LatestShipmentTrackingReportsService);

LatestShipmentTrackingReportsService.$inject = ['$rootScope', 'SentinelUiSession', 'localStorageService', 'ShipmentsReportService', 'UtilityService'];

function LatestShipmentTrackingReportsService($rootScope, SentinelUiSession, localStorageService, ShipmentsReportService, UtilityService) {
    const pageSize = 500;
    let totalCount = 0;

    const service = {
        dateKey: 'shipmentTrackingFilter',
        lastClientGuid: null,
        reports: [],
        selected: null,
        currentPage: 1,
        maxPage: 1,
        sortColumn: 'MessageTimeStamp',
        sortAsc: false,
        load: load,
        goToPage: goToPage,
        goToSort: goToSort,
        get: get,
        fromDate: moment().subtract(7, 'day').startOf('day'),
        toDate: moment().add(1, 'day').endOf('day'),
        fromDateCache: moment().subtract(7, 'day').startOf('day'),
        toDateCache: moment().add(1, 'day').endOf('day'),
        reportsCache: [],
        isFull: false,
        clear: clear,
        restartCache: restartCache
    };
    activate();
    return service;

    ///////////////////////////////////////////////////

    function activate() {
        // in case this is a page reload event, check for 'cached' dates and use those
        const savedFromDate = UtilityService.getSetting(`${service.dateKey}.fromDate`);
        const savedToDate = UtilityService.getSetting(`${service.dateKey}.toDate`);
        if (savedFromDate) {
            service.fromDate = moment(savedFromDate);
        }
        if (savedToDate) {
            service.toDate = moment(savedToDate);
        }

        $rootScope.$on('CLIENT_FOCUS_CHANGED', function (event, args) {
            clear();
        });
    }

    function restartCache() {
        clear();
    }

    function clear() {
        service.lastClientGuid = null;
        service.selected = null;
        service.reports = [];
        service.isFull = false;

        const savedFromDate = UtilityService.getSetting(`${service.dateKey}.fromDate`);
        const savedToDate = UtilityService.getSetting(`${service.dateKey}.toDate`);

        service.fromDate = savedFromDate ? moment(savedFromDate) : moment().subtract(7, 'day').startOf('day');
        service.toDate = savedToDate ? moment(savedToDate) : moment().add(1, 'day').endOf('day');
    }

    function filterCurrentResult(fromMoment, toMoment) {
        if (service.reports) {
            service.reports = service.reportsCache.filter(function (r) {
                const messageTimeStamp = moment(r.messageTimeStamp);
                return !!(fromMoment.isSameOrBefore(messageTimeStamp) && messageTimeStamp.isSameOrBefore(toMoment));

            });
            service.isFull = false;
        }
    }

    function goToPage(page) {
        service.currentPage = page;
        load(service.fromDate, service.toDate, true);
    }

    function goToSort(sortColumn, sortAsc) {
        service.sortColumn = sortColumn;
        service.sortAsc = sortAsc;
        load(service.fromDate, service.toDate, true);
    }

    function load(fromDate, toDate, refresh) {
        if (!fromDate || !toDate) {
            clear();
            return;
        }

        const oneYearAgo = moment().subtract(1, 'year');
        const fromMoment = moment.isMoment(fromDate) ? fromDate : moment(fromDate);
        const toMoment = moment.isMoment(toDate) ? toDate : moment(toDate);
        const maxToMoment = moment(fromMoment).add(45, 'day');

        if (fromMoment.isBefore(oneYearAgo) || toMoment.isBefore(fromMoment) || toMoment.isAfter(maxToMoment)) {
            return;
        }

        if (!refresh && service.lastClientGuid === SentinelUiSession.focus.clientGuid &&
            fromMoment.isSame(service.fromDate) && toMoment.isSame(service.toDate)) {
            //do not reload the same data set again
            //return;
        }

        service.lastClientGuid = SentinelUiSession.focus.clientGuid;
        service.selected = null;
        service.fromDate = fromMoment;
        service.toDate = toMoment;
        totalCount = 0;

        if (service.fromDateCache && service.fromDateCache.isBefore(fromMoment) &&
            service.toDateCache && toMoment.isBefore(service.toDateCache) && service.reportsCache.length > 0) {
            filterCurrentResult(fromMoment, toMoment);
            return;
        }

        service.fromDateCache = service.fromDate.clone();
        service.toDateCache = service.toDate.clone();


        $rootScope.loading = true;

        const promise = ShipmentsReportService.getLatestReports(
            SentinelUiSession.focus,
            service.fromDate,
            service.toDate,
            service.currentPage,
            pageSize,
            service.sortColumn,
            service.sortAsc
        ).$promise;

        promise.then(
            function (response) {
                const results = response.page;
                totalCount = response.totalCount;
                for (let i = results.length - 1; i >= 0; i--) {
                    const r = results[i];
                    const activatedAlarms = r.activatedAlarms.map(function (item) {
                        return item.alarmName;
                    });
                    r.activatedAlarms = r.activatedAlarms.filter(function (item, index) {
                        return activatedAlarms.indexOf(item.alarmName) >= index;
                    });
                    r.isShockExceeded = (r.shockX || r.shockY || r.shockZ);
                    if (r.isShockExceeded) {
                        r.shockMagnitude = Math.sqrt(Math.pow(r.shockX, 2) + Math.pow(r.shockY, 2) + Math.pow(r.shockZ, 2)).toFixed(1);
                    }
                    r.isTiltExceeded = (r.tiltX || r.tiltY || r.tiltZ);
                    if (r.isTiltExceeded) {
                        r.tiltMagnitude = Math.sqrt(Math.pow(r.tiltX, 2) + Math.pow(r.tiltY, 2) + Math.pow(r.tiltZ, 2)).toFixed(1);
                    }
                }
                service.reports = results;
                service.reportsCache = results.slice();
                service.totalCount = isNaN(totalCount) ? results.length : totalCount;
                service.maxPage = isNaN(totalCount) ? 1 : Math.ceil(totalCount / pageSize);
                service.isFull = false;
            },
            function (error) {
                console.log(error);
                //todo: handle error
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function get(shipmentId) {
        let result = null;
        _.forEach(service.reports, function (report) {
            if (report.shipment.shipmentId === shipmentId) {
                result = report;
                return false;
            }
        });
        return result;
    }
}
