import angular from "angular";
import moment from "moment/moment";

const dateFormatService = ($rootScope) => ({
    format: (value, options) => {

        if (!value) {
            return '';
        }

        if (value.replace) {
            value = value.replace(/"/g, '');
        }

        if (options && options.showLocalTime === 'false') {
            options.showLocalTime = false;
        }

        const dateFormat = options && options.dateFormat ? options.dateFormat : 'YYYY-MM-DD';
        const showLocalTime = options && options.showLocalTime ? options.showLocalTime : !$rootScope.isOrs;
        const hideUtc = options && (options.hideUtc === 'true' || options.hideUtc === '');
        const utc = hideUtc ? '' : ' UTC';
        const defaultTimeFormat = showLocalTime ? 'hh:mm:ss A' : `HH:mm:ss${utc}`;
        const timeFormat = timeFormat && options.timeFormat ? options.timeFormat : defaultTimeFormat

        const m = moment(value);
        const base = showLocalTime ? m.local() : m.utc();
        return base.format(dateFormat + (timeFormat ? ' ' + timeFormat : ''));
    }
});

angular.module('ui-common').factory('DateFormatService', ['$rootScope', dateFormatService]);
