import angular from "angular";
import bootstrap from "bootstrap";
import Cookies from 'js-cookie';

angular
    .module('ui-sentinel.header')
    .controller('HeaderController', HeaderController);

HeaderController.$inject = ['$rootScope', '$scope', '$state', 'ApiToken', 'SentinelUiSession'];

function HeaderController($rootScope, $scope, $state, ApiToken, SentinelUiSession) {
    var vm = {
        references: {
            home: 'sentry'
        },
        focusName: null,
        accountName: null,
        showNavigation: showNavigation,
        showLogout: showLogout,
        showMenu: false,
        showAdminMenu: showAdminMenu,
        showAccountMenu: showAccountMenu,
        showSystemAdminMenu: showSystemAdminMenu,
        showAccountAdminMenu: showAccountAdminMenu,
        showAccountEditorMenu: showAccountEditorMenu,
        showAccountObserverMenu: showAccountObserverMenu,
        logout: logout,
        go: go,
        toggleMenu: toggleMenu,
        navigateToParent: navigateToParent,
        clearFocus: clearFocus,
        changeFocus: changeFocus,
        changePassword: changePassword,
        changeUsername: changeUsername,
        session: SentinelUiSession,
        hasTitle: hasTitle,
        accountDisplayName: accountDisplayName,
        accountButtonDisplayNameNarrow: accountButtonDisplayNameNarrow,
        isOrs: $rootScope.isOrs
    };
    activate();


    (function ($bs) {
        const CLASS_NAME = 'has-child-dropdown-show';
        $bs.Dropdown.prototype.toggle = function (_orginal) {
            return function () {
                document.querySelectorAll('.' + CLASS_NAME).forEach(function (e) {
                    e.classList.remove(CLASS_NAME);
                });
                let dd = this._element.closest('.dropdown').parentNode.closest('.dropdown');
                for (; dd && dd !== document; dd = dd.parentNode.closest('.dropdown')) {
                    dd.classList.add(CLASS_NAME);
                }
                return _orginal.call(this);
            }
        }($bs.Dropdown.prototype.toggle);

        document.querySelectorAll('.dropdown').forEach(function (dd) {
            dd.addEventListener('hide.bs.dropdown', function (e) {
                if (this.classList.contains(CLASS_NAME)) {
                    this.classList.remove(CLASS_NAME);
                    e.preventDefault();
                }
                e.stopPropagation(); // do not need pop in multi level mode
            });
        });
    })(bootstrap);

    return vm;

    function activate() {

        $scope.$watch(
            function () {
                return SentinelUiSession.focus;
            },
            function (focus) {
                if (SentinelUiSession.user) {
                    vm.accountName = SentinelUiSession.user.accountName;
                }

                if (!focus || focus.id === SentinelUiSession.user.accountId) {
                    vm.focusName = null;
                } else {
                    vm.focusName = focus.name;
                }
            }
        );
    }

    function showNavigation() {
        return SentinelUiSession.user && SentinelUiSession.user.isEulaAgreement && true;
    }

    function showLogout() {
        return SentinelUiSession.user && !SentinelUiSession.user.isEulaAgreement && true;
    }

    function showSystemAdminMenu() {
        return SentinelUiSession.user && SentinelUiSession.user.isSystemAdmin;
    }

    function showAdminMenu() {
        return (SentinelUiSession.user && SentinelUiSession.user.isAnAdmin);
    }

    function showAccountMenu() {
        return (SentinelUiSession.user && !SentinelUiSession.user.isAnAdmin) || (SentinelUiSession.user && SentinelUiSession.user.isAnAdmin && SentinelUiSession.focus.id !== SentinelUiSession.user.accountId);
    }

    function showSupportAdminMenu() {
        return (SentinelUiSession.user && SentinelUiSession.user.isSupportAdmin);
    }

    function showSupportObserverMenu() {
        return (SentinelUiSession.user && SentinelUiSession.user.isSupportObserver);
    }

    function showAccountAdminMenu() {
        return (SentinelUiSession.user && SentinelUiSession.user.isAccountAdmin);
    }

    function showAccountEditorMenu() {
        return (SentinelUiSession.user && SentinelUiSession.user.isAccountEditor);
    }

    function showAccountObserverMenu() {
        return (SentinelUiSession.user && SentinelUiSession.user.isAccountObserver);
    }

    function go(state) {
        vm.showMenu = false;
        // $('#offcanvas').removeClass('show');

        $('#closeButton').click();

        if ($state.current.name !== state) {
            $state.go(state);
        }
    }

    function logout() {
        vm.showMenu = false;
        const domain = SentinelUiSession.rootDomainForCookie();
        const path = '';
        const secure = true;
        const sameSite = 'None';
        Cookies.remove('oa_auth', { domain, path, secure, sameSite });
        ApiToken.clear();
        SentinelUiSession.clear();
        $state.go('login');
    }

    function toggleMenu() {
        vm.showMenu = !vm.showMenu;
    }

    function navigateToParent() {
        $state.go($state.current.data.parentState);
    }

    function hasTitle() {
        return $state.current?.data?.parentState || $state.current?.data?.pageTitle || $state.current?.data?.subTitle;
    }

    function accountDisplayName() {
        return this.focusName || this.accountName;
    }

    function accountButtonDisplayNameNarrow() {
        return this.isOrs && this.hasTitle() ? '' : this.accountDisplayName();
    }

    function clearFocus() {
        vm.focusName = null;
        SentinelUiSession.setFocus(null);
        $state.go('accounts.list');
    }

    function changeFocus() {
        $state.go('accounts.list');
    }

    function changePassword() {
        var returnState = $state.current.name;
        var returnStateParams = $state.params;
        $state.go('logins.password', { referrer: returnState, referrerParams: returnStateParams });
    }

    function changeUsername() {
        var returnState = $state.current.name;
        var returnStateParams = $state.params;
        $state.go('logins.username', {
            loginId: SentinelUiSession.user.id,
            referrer: returnState,
            referrerParams: returnStateParams
        });
    }
}
