import angular from "angular";
import sortBy from "lodash/sortBy";

const isSortedByValue = array => {
    if (array.length <= 1) {
        return true;
    }

    for (let i = 1; i < array.length; i++) {
        if (array[i].value < array[i - 1].value) {
            return false;
        }
    }

    return true;
};

const sortMenu = (ul) => {
    const nodes = [];
    angular.forEach(ul.childNodes, (elem) => {
        if (elem.nodeType !== 1) {
            return;
        }
        const value = elem.innerText.replace(/\W/g, '');
        nodes.push({
            value,
            elem
        });
    });

    // are they already sorted?
    if (isSortedByValue(nodes)) {
        // we're good
        return;
    }
    const sorted = sortBy(nodes, 'value');

    const elements = sorted.map(s => s.elem);

    ul.replaceChildren(...elements);
}

// menu-sorter
angular.module('ui-common')
    .directive('menuSorter', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
        return {
            restrict: 'A',
            scope: {},
            link: function (scope, element) {
                if (!$rootScope.isOrs) {
                    return;
                }

                $timeout(() => sortMenu(element[0]), 0);

                const observer = new MutationObserver(mutations => {
                    for (const mutation of mutations) {
                        if (mutation.type === "childList" || mutation.type === "characterData") {
                            $timeout(() => sortMenu(element[0]), 0);
                        }
                    }
                });

                const config = {
                    childList: true,
                    characterData: true,
                    subtree: true
                };

                observer.observe(element[0], config);
            }
        };
    }]);
