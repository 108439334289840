import angular from "angular";
import moment from "moment/moment";
import "core-js";

import './app-module';
import './app-theme';
import './app-constants';
import './app-routes';
import './app-controller.js';

angular
    .module('sentinel.gateway.web')
    .run(runBlock);

runBlock.$inject = ['$rootScope', '$state', '$stateParams', '$transitions', '$trace', '$location', 'SentinelUiSession', 'localStorageService', 'FeedbackService'];

function runBlock($rootScope, $state, $stateParams, $transitions, $trace, $location, SentinelUiSession, localStorageService, FeedbackService) {

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    document.title = $rootScope.isOrs ? 'ORS' : 'OAInsight';

    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);

    $(document).click(function (e) {
        if (e.target.id === 'closeButton' ||
            e.target.id === 'btn-toggle-menu-icon' ||
            $(e.target).hasClass('offcanvas') ||
            $(e.target).hasClass('btn-toggle') ||
            $(e.target).hasClass('offcanvas-body') ||
            $(e.target).hasClass('sidebar-nav-item') ||
            $(e.target).hasClass('sidebar-nav-member')) {
            return;
        }
        // if clicking anywhere else, close the dropdown
        if ($('#offcanvas').hasClass('show')) {
            $('#closeButton').click();
        }
    });

    // uncomment to enable transition tracing
    // $trace.enable("TRANSITION");

    $transitions.onStart({}, async function (transition) {
        FeedbackService.clear();
        localStorageService.set('isRlsRoute', false);
        const toState = transition.to();

        if (!SentinelUiSession.isValid()) {
            await SentinelUiSession.initFromCookie();
        }

        if (SentinelUiSession.isValid() && SentinelUiSession.isExpired() && toState.name !== 'logins.password') {
            event.preventDefault();
            $rootScope.loading = false;
            $state.go('logins.password');
            return;
        }

        if (SentinelUiSession.isValid() && !SentinelUiSession.isEulaAgreement() && !SentinelUiSession.isExpired() && toState.name !== 'logins.termofservice') {
            event.preventDefault();
            $rootScope.loading = false;
            $state.go('logins.termofservice');
            return;
        }

        if (toState.name === 'login') {
            if (SentinelUiSession.isValid()) {
                $location.path("/home");
            }
            return;
        }

        if (toState.data.authorizationRequired && !SentinelUiSession.isValid()) {
            event.preventDefault();
            SentinelUiSession.clear();
            $state.go('login');
            return;
        }

        var reloadState = localStorageService.get('reloadState');
        var reloadParams = localStorageService.get('reloadParams');

        if (SentinelUiSession.isReloaded && reloadState) {
            event.preventDefault();
            SentinelUiSession.isReloaded = false;
            $state.go(reloadState, reloadParams);
        }
        SentinelUiSession.isReloaded = false;
    });
}
