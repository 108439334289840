import angular from "angular";
import {DateTime, TempusDominus} from '@eonasdan/tempus-dominus';
import moment from "moment/moment";
import set from "lodash/set";
import get from "lodash/get";

const display = {
    icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-arrow-up',
        down: 'fa fa-arrow-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-calendar-check-o',
        clear: 'fa fa-trash',
        close: 'fa fa-check',
    },
    sideBySide: true,
    buttons: {
        today: true,
        clear: true,
        close: true,
    }
};

const dateMatchRegex = new RegExp('\\b\\d{4}-\\d{2}-\\d{2}\\s\\d{2}:\\d{2}\\s(AM|PM|UTC)\\b');

// date-time-picker
const dateTimePickerDirective = ($rootScope) => {
    return {
        require: '?ngModel',
        restrict: 'AE',
        scope: {
            ngModel: '=',
            useCurrent: '@',
            showTime: '@',
            showSeconds: '@',
            noMax: '@',
            displayInUtc: '@',
            modelIsDate: '@',
            allowBlank: '@',
        },
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

            const noMax = scope.noMax === 'true' || scope.noMax === '';
            const modelIsDate = scope.modelIsDate === 'true' || scope.modelIsDate === '';
            const allowBlank = scope.allowBlank === 'true' || scope.allowBlank === '';
            if (!modelIsDate && scope.ngModel && scope.ngModel.includes && scope.ngModel.includes('T')) {
                scope.ngModel = scope.ngModel.replace('T', ' ');
            }

            const displayInUtc =
                scope.displayInUtc === undefined && $rootScope.isOrs
                    ? true
                    : scope.displayInUtc === 'true';

            const jqElem = $(elem);

            jqElem.css('position', 'relative');

            let format = scope.showSeconds ? 'yyyy-MM-dd hh:mm:ss' : 'yyyy-MM-dd hh:mm';

            const momentFormat = format.replace('yyyy-MM-dd hh', 'YYYY-MM-DD HH');
            let momentFormatDisplay = momentFormat;

            if (displayInUtc) {
                momentFormatDisplay = momentFormat.replace('hh', 'HH');
                momentFormatDisplay += ' UTC';
            } else {
                momentFormatDisplay = momentFormat.replace('HH', 'hh');
                momentFormatDisplay += ' A';
            }

            if (displayInUtc) {
                format = format.replace('hh', 'HH');
                format += ` U[T]C`;
            } else {
                format += ' T';
            }

            const localization = {
                format,
                hourCycle: displayInUtc ? 'h23' : 'h12',
            }

            const localAsUtc = (date) => {
                date = date || new Date();
                return new Date(moment(date).utc().format().replace('T', ' ').replace('Z', ''))
            }

            const restrictions = noMax
                ? {} : {
                    maxDate: displayInUtc ? localAsUtc() : moment().toDate()
                };

            const getFormattedDatesFromModel = () => {
                if (allowBlank && !scope.ngModel) {
                    return { cleaned: '', defaultDate: undefined };
                }
                const defaultDateMoment = scope.ngModel ? moment(new Date(scope.ngModel)) : moment();
                const base = displayInUtc ? defaultDateMoment.utc() : defaultDateMoment.local();
                const cleaned = base.format().replace('T', ' ').replace('Z', '');
                const defaultDate = new Date(cleaned);
                return { cleaned, defaultDate };
            }

            const { cleaned, defaultDate } = getFormattedDatesFromModel();

            const picker = new TempusDominus(elem[0], {});
            picker.clear();

            const options = {
                useCurrent: !!scope.useCurrent,
                keepInvalid: true,
                display,
                localization,
                restrictions
            }

            if (defaultDate) {
                options.defaultDate = defaultDate;
            }

            picker.updateOptions(options);

            picker.subscribe('update.td', (e) => {
                // this only fires wen changing the month view.
                // don't change the selected date on a view change.
                if (ngModel.$viewValue) {
                    picker.dates._dates[0] = new DateTime(ngModel.$viewValue);
                }
            });

            const utcFromDate = (date) => {
                const m = moment(date);
                return m.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
            }

            const makeDateInUtc = (date) => {
                return new Date(utcFromDate(date));
            }

            const setValue = (dt = undefined) => {
                const noPickerDateSelected = picker.dates._dates.length === 0;
                if (!dt && noPickerDateSelected && allowBlank) {
                    ngModel.$setViewValue('');
                    return;
                }

                const vd = dt || picker.viewDate;
                const value = displayInUtc ? makeDateInUtc(vd) : vd;

                if (modelIsDate) {
                    ngModel.$setViewValue(value);
                } else {
                    const formatted = moment(value).format(momentFormat);
                    ngModel.$setViewValue(formatted);
                }
            }

            const formatForDisplay = (str) =>
                str || !allowBlank
                    ? moment(str).format(momentFormatDisplay)
                    : '';

            elem[0].value = formatForDisplay(cleaned);

            if (defaultDate) {
                setValue(defaultDate);
            } else {
                ngModel.$setViewValue('');
            }

            jqElem.on('change', function () {
                setValue();
            });

            scope.$watch('ngModel', function () {

                if (allowBlank && !scope.ngModel) {
                    elem[0].value = '';
                    return;
                }

                const goingToBlank = allowBlank && !scope.ngModel;

                if (!goingToBlank && (scope.ngModel || scope.ngModel === '') && scope.ngModel.replace) {
                    if (!dateMatchRegex.test(scope.ngModel)) {
                        return;
                    }
                }

                const { cleaned, defaultDate } = getFormattedDatesFromModel();

                picker.updateOptions({
                    defaultDate: defaultDate,
                    viewDate: defaultDate
                });

                // DateTime is a class from the tempus-dominus library
                const pickerDate = new DateTime(defaultDate);

                picker.viewDate = pickerDate;

                // have to hack this private property to get the picker to update if there is already a value
                const exists = get(picker, ['dates', '_dates', 0]);
                if (exists) {
                    set(picker, ['dates', '_dates', 0], pickerDate);
                }

                elem[0].value = formatForDisplay(cleaned);
            })
        }
    };
}

angular.module('ui-common').directive('dateTimePicker', ['$rootScope', dateTimePickerDirective]);
