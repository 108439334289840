import angular from "angular";
import $ from 'jquery';

angular
    .module('ui-sentinel.logins')
    .controller('TermOfServiceController', TermOfServiceController);

TermOfServiceController.$inject = ['$rootScope', '$scope', '$element', '$sce', '$state', '$document', '$timeout', '$stateParams', 'localStorageService', 'SentinelUiSession', 'LoginsApiService', 'FeedbackService'];

const scrollErrorMessage = 'Please scroll to the end of the agreement before accepting.';

function TermOfServiceController($rootScope, $scope, $element, $sce, $state, $document, $timeout, $stateParams, localStorageService, SentinelUiSession, LoginsApiService, FeedbackService) {
    const vm = {
        orsEulaDivId: 'ors-eula',
        isOrs: $rootScope.isOrs,
        accept: false,
        actions: {
            submit: submit
        },
        session: SentinelUiSession,
        feedback: FeedbackService,
        scrolledToEnd: !$rootScope.isOrs
    };

    activate();

    return vm;

    ////////////////////////////////////////////

    function activate() {
        if (vm.isOrs) {
            const clearWatch = $scope.$watch(() => {
                return document.getElementById(vm.orsEulaDivId);
            }, (newValue) => {
                if (newValue !== null) {
                    clearWatch();

                    const scrollable = document.getElementById(vm.orsEulaDivId);
                    scrollable.addEventListener('scroll', function() {
                        if (scrollable.scrollTop + scrollable.clientHeight >= scrollable.scrollHeight) {
                            $scope.$apply(function() {
                                vm.scrolledToEnd = true;
                                vm.feedback.clearError(scrollErrorMessage);
                            });
                        }
                    });
                }
            });
        }
    }

    function close() {
        $state.go($stateParams.referrer, $stateParams.referrerParams);
    }

    function submit() {

        if (vm.isOrs && !vm.scrolledToEnd) {
            vm.feedback.addError(scrollErrorMessage);
            return;
        }

        $('#btn-submit').blur();

        $rootScope.loading = true;
        var promise = LoginsApiService.agreedEULAA(SentinelUiSession.user).$promise;
        promise.then(
            function (result) {
                SentinelUiSession.user.isEulaAgreement = result.eulaAgreement;
                localStorageService.set('user', SentinelUiSession.user);
                $state.go('home');
            },
            function (error) {
                if (error.status === -1) {
                    vm.feedback.addError('Unable to connect to server.  Please try again later.');
                    return;
                }

                vm.feedback.addError('Unable to accept terms.  Please try again later or call support.');
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }
}
