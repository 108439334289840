import angular from "angular";
import moment from "moment";
import sortBy from "lodash/sortBy";

angular
    .module('ui-sentinel.shipments')
    .factory('ShipmentEditorService', ShipmentEditorService);

ShipmentEditorService.$inject = ['$rootScope', 'ShipmentsService', 'SentinelUiSession', 'FeedbackService', 'DevicesService', 'RadialGeofencesService', 'PolygonGeofencesService', 'DatetimeValidatorService', 'SentryAdminApiService', 'SentryAccountApiService', 'TimeZoneList'];

function ShipmentEditorService($rootScope, ShipmentsService, SentinelUiSession, FeedbackService, DevicesService, RadialGeofencesService, PolygonGeofencesService, DatetimeValidatorService, SentryAdminApiService, SentryAccountApiService, TimeZoneList) {
    const emailValidator = /^([\w+-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i;
    let nextStopId = 0;

    const service = {
        feedback: FeedbackService,
        availableGeofences: null,
        beginTrackingStrategyTypeOld: null,
        shipment: null,
        shipmentDevice: null,
        referencePrefix: null,
        loadGeofences: loadGeofences,
        referenceNumber: {
            value: null,
            isPristine: true,
            hasError: function () {
                return this.errors.isBlank || this.errors.isDuplicate || this.errors.isWrongPrefix || this.errors.isTooLong || this.errors.hasInvalidCharacters;
            },
            errors: {
                isBlank: true,
                isDuplicate: false,
                isWrongPrefix: false,
                isTooLong: false,
                hasInvalidCharacters: false
            },
            validate: validateReferenceNumber
        },
        endDate: {
            value: moment().add(1, 'day').toDate(),
            isPristine: true,
            hasError: function () {
                return this.errors.isBlank || this.errors.isBeforeNow || this.errors.isNotADate || this.errors.isTimeBlank || this.errors.isNotATime;
            },
            errors: {
                isBlank: true,
                isBeforeNow: false,
                isNotADate: false
            },
            validate: validateEndDate
        },
        beginDate: {
            value: moment().toDate(),
            isPristine: true,
            hasError: function () {
                return this.errors.isBlank || this.errors.isBeforeNow || this.errors.isNotADate || this.errors.isTimeBlank || this.errors.isNotATime;
            },
            errors: {
                isBlank: true,
                isBeforeNow: false,
                isNotADate: false,
                isNotATime: false,
                isTimeBlank: false
            },
            validate: validateBeginDate
        },
        device: {
            value: null,
            isPristine: true,
            hasError: function () {
                return this.value === null;
            },
            errors: {
                isBlank: true,
                isNotFound: false
            },
            validate: validateDevice
        },
        beginGeofence: {
            value: null,
            isPristine: true,
            hasError: function () {
                return this.errors.isBlank;
            },
            errors: {
                isBlank: true,
                isNotFound: false
            },
            validate: validateBeginGeofence
        },
        endGeofence: {
            value: null,
            isPristine: true,
            hasError: function () {
                return this.errors.isBlank;
            },
            errors: {
                isBlank: true,
                isNotFound: false
            },
            validate: validateEndGeofence
        },
        arrivalLightLevel: {
            value: null,
            min: 0,
            max: 100,
            hasError: function () {
                return this.errors.belowMin || this.errors.aboveMax || this.errors.thisOrGeofence;
            },
            errors: {
                belowMin: false,
                aboveMax: false,
                thisOrGeofence: false
            },
            validate: validateArrivalLightLevel
        },
        completionDelay: {
            value: null,
            min: 1,
            max: 240,
            hasError: function () {
                return this.errors.belowMin || this.errors.aboveMax;
            },
            errors: {
                belowMin: false,
                aboveMax: false
            },
            validate: function () {
                if (this.value < this.min) {
                    this.errors.belowMin = true;
                    return;
                }
                if (this.value > this.max) {
                    this.errors.aboveMax = true;
                    return;
                }
                this.errors.belowMin = false;
                this.errors.aboveMax = false;
            }
        },
        endGeofence2: {
            value: null,
            isPristine: true,
            hasError: function () {
                return this.errors.isBlank || this.errors.thisOrLight;
            },
            errors: {
                isBlank: true,
                isNotFound: false,
                thisOrLight: false
            },
            validate: validateEndGeofence2
        },
        sentinels: [],
        notes: {
            value: null
        },
        beginTrackingStrategyType: {
            value: 'date/time',
            isPristine: true,
            hasError: function () {
                return false;
            },
            errors: {},
            validate: function () {

            }
        },
        endTrackingStrategyType: {
            value: 'date/time',
            isPristine: true,
            hasError: function () {
                return false;
            },
            errors: {
                isBlank: true
            },
            validate: function () {
                this.isPristine = false;
                this.errors.isDuplicate = false;
                this.errors.isBlank = !this.value;
            }
        },
        trackDeviceReturn: {
            value: false,
            isPristine: true,
            hasError: function () {
                return false;
            },
            errors: {},
            validate: function () {
            }
        },
        subscribers: [],
        shipmentEmails: {
            value: null,
            isPristine: true,
            hasError: function () {
                return this.errors.invalidEmails.length > 0;
            },
            errors: {
                invalidEmails: []
            },
            validate: validateSubscribers,
            asString: function () {
                if (this.value === null || this.value === undefined) {
                    return '';
                }
                if (Array.isArray(this.value)) {
                    return sortBy(this.value, (str) => str.toLowerCase()).join('; ');
                }
                return this.value.toString();
            }
        },
        stops: {
            origin: newEditorStop('blank'),
            other: [],
            destination: newEditorStop('blank'),
            hasError: stopsHaveError
        },
        isValid: false,
        isValidInfo: false,
        init: init,
        addStop: addStop,
        removeStop: removeStop,
        buildArrayEmails: buildArrayEmails,
        joinEmails: joinEmails,
        saveNew: saveNew,
        saveNewStops: saveNewStops,
        preSaveShipmentInfo: preSaveShipmentInfo,
        saveShipmentInfo: saveShipmentInfo,
        newEditorStop: newEditorStop,
        editorStop: editorStop,
        stopToSave: stopToSave,
        edit: edit,
        create: create,
        reset: reset,
        clear: clear,
        validate: validate,
        validateStops: validateStops,
        canEdit: true,
        completeShipment: completeShipment,
        shipmentStopAddUiProperties: shipmentStopAddUiProperties
    };
    return service;

    function addStop(type) {

        if (!service.canEdit) {
            service.feedback.addError('Shipment can not be changed');
            return null;
        }

        const stop = newEditorStop(type === null ? 'blank' : type);
        stop.stopId = nextStopId++;
        service.stops.other.push(stop);
        console.log("addStop", stop);
        return stop;
    }

    function clear() {
        service.shipment = null;
        service.shipmentDevice = null;
        service.availableGeofences = [];
    }

    function create() {
        clear();
        reset();
    }

    function edit(shipment) {
        clear();
        service.shipment = shipment;
        service.beginTrackingStrategyTypeOld = shipment.shipmentInfo.beginTrackingStrategyType;

        console.log("service.beginTrackingStrategyType", service.beginTrackingStrategyTypeOld);

        service.canEdit = shipment.shipmentInfo.status.toLowerCase() !== 'cancelled' && shipment.shipmentInfo.status.toLowerCase() !== 'completed';
        loadDevice();
        reset();
        //loadGeofences();  //todo: should use a service so that it does not have to reload these from API
    }

    function editorStop(shipmentStop) {
        const stop = newEditorStop('blank');

        if (shipmentStop.address === shipmentStop.nameForAddress) {
            stop.type = 'address';
            stop.address.value = shipmentStop.address;
            stop.locationSearch.value = shipmentStop.address;
        } else {
            stop.type = 'geofence';
            stop.geofence.name = shipmentStop.nameForAddress;
            stop.geofence.value = _.find(service.availableGeofences, { name: shipmentStop.nameForAddress });
        }
        stop.destinationId = shipmentStop.destinationId;

        stop.addressLatitude = shipmentStop.addressLatitude;
        stop.addressLongitude = shipmentStop.addressLongitude;

        // timeZoneOffset is just an id defined globally in app-constants.js
        stop.timeZoneOffset = shipmentStop.timeZoneOffset;
        stop.expectedArrivalDateTime = shipmentStop.expectedArrivalDateTime;
        stop.expectedDepartureDateTime = shipmentStop.expectedDepartureDateTime;

        return stop;
    }

    function shipmentStopAddUiProperties(shipmentStop) {
        shipmentStop.timeZoneOffsetName = '';
        shipmentStop.expectedArrivalDateTimeLocal = '';
        shipmentStop.expectedDepartureDateTimeLocal = '';
        if (shipmentStop.timeZoneOffset) {
            const zone = TimeZoneList.find(tz => tz.id === shipmentStop.timeZoneOffset);
            if (!zone) {
                // could not find the time zone for the id
                shipmentStop.timeZoneError = `Error: Could not find time zone ${shipmentStop.timeZoneOffset}.`;
            } else {
                shipmentStop.timeZoneOffsetName = zone.label;
                if (shipmentStop.expectedArrivalDateTime) {
                    const arrival = moment.utc(shipmentStop.expectedArrivalDateTime).tz(zone.name);
                    shipmentStop.expectedArrivalDateTime = arrival.format('YYYY-MM-DDTHH:mm:ss');
                    shipmentStop.expectedArrivalDateTimeLocal = arrival.format('YYYY-MM-DD HH:mm:ss');
                }
                if (shipmentStop.expectedDepartureDateTime) {
                    const departure = moment.utc(shipmentStop.expectedDepartureDateTime).tz(zone.name);
                    shipmentStop.expectedDepartureDateTime = departure.format('YYYY-MM-DDTHH:mm:ss');
                    shipmentStop.expectedDepartureDateTimeLocal = departure.format('YYYY-MM-DD HH:mm:ss');
                }
            }
        }
        return shipmentStop;
    }

    function init() {
        clear();
        reset();
        loadGeofences();  //todo: should use a service so that it does not have to reload these from API
    }

    function loadDevice() {
        const promise = SentryAccountApiService.getDevicesForASML(SentinelUiSession.focus, service.shipment.shipmentInfo.deviceTagId).$promise;

        promise.then(
            function (result) {
                if (result.length) {
                    service.shipmentDevice = result[0];
                    service.device.value = result[0];
                } else {
                    service.shipmentDevice = undefined;
                    service.device.value = undefined;
                    service.feedback.addError(`Warning: device not found: ${service.shipment.shipmentInfo.deviceTagId}`);
                }
            },
            function (error) {
                console.log(error);
                service.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function loadGeofence(geofenceId, type) {
        const radialPromise = RadialGeofencesService.getGeofence(geofenceId).$promise;
        radialPromise.then(
            function (result) {
                if (typeof type !== undefined) {
                    if (type === "begin") {
                        if (service.beginTrackingStrategyType.value === "ArrivalGeofence") {
                            service.endGeofence.value = result;
                        } else if (service.beginTrackingStrategyType.value === "DepartureGeofence") {
                            service.beginGeofence.value = result;
                        }
                    } else if (type === "end") {
                        service.endGeofence2.value = result;
                    }
                }
            },
            function (error) {
                if (error.status !== 404) {
                    console.log(error);
                    vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
                }
            }
        );

        const polygonPromise = PolygonGeofencesService.getGeofence(geofenceId).$promise;
        polygonPromise.then(
            function (result) {
                if (typeof type !== undefined) {
                    if (type === "begin") {
                        if (service.beginTrackingStrategyType.value === "ArrivalGeofence") {
                            service.endGeofence.value = result;
                        } else if (service.beginTrackingStrategyType.value === "DepartureGeofence") {
                            service.beginGeofence.value = result;
                        }
                    } else if (type === "end") {
                        service.endGeofence2.value = result;
                    }
                }
            },
            function (error) {
                if (error.status !== 404) {
                    console.log(error);
                    vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
                }
            }
        );
    }

    function loadGeofences() {
        console.log("loadGeofences.....");
        service.availableGeofences = [];
        service.stops.origin.geofence.name = null;
        service.stops.origin.geofence.value = null;
        service.stops.destination.geofence.name = null;
        service.stops.destination.geofence.value = null;
        _.forEach(service.stops.other, function (stop) {
            stop.geofence.name = null;
            stop.geofence.value = null;
        });

        const radialPromise = RadialGeofencesService.getGeofences(SentinelUiSession.focus).$promise;
        radialPromise.then(
            function (result) {
                //console.log("Geofences", result);
                service.availableGeofences = service.availableGeofences.concat(result);
                console.log(service.availableGeofences);
            },
            function (error) {
                service.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );

        const polygonPromise = PolygonGeofencesService.getGeofences(SentinelUiSession.focus).$promise;
        polygonPromise.then(
            function (result) {
                //console.log("Geofences", result);
                service.availableGeofences = service.availableGeofences.concat(result);
            },
            function (error) {
                service.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function newEditorStop(type) {
        return {
            type: type,
            address: {
                value: null,
                isPristine: true,
                isChanging: false,
                hasError: function () {
                    return this.errors.isBlank;
                },
                errors: {
                    isBlank: true
                },
                validate: function () {
                    this.isPristine = false;
                    this.errors.isBlank = !this.value;
                }
            },
            geofence: {
                name: null,
                value: null,
                isPristine: true,
                isChanging: false,
                hasError: function () {
                    return this.errors.isBlank;
                },
                errors: {
                    isBlank: true
                },
                validate: function () {
                    this.isPristine = false;
                    this.errors.isBlank = !this.value;
                }
            },
            locationSearch: {
                value: null,
                location: null,
                availableLocations: [],
                isPristine: true,
                hasError: function () {
                    return this.errors.isBlank
                        || this.errors.hasZeroResults
                        || this.errors.isLocationMissing;
                },
                errors: {
                    isBlank: true,
                    isLocationMissing: false,
                    hasZeroResults: false
                },
                validate: function () {
                    this.isPristine = false;
                    this.errors.isBlank = !this.value;
                    this.errors.isLocationMissing = false;
                    this.errors.hasZeroResults = false;

                    if (!this.errors.isBlank) {
                        this.errors.isLocationMissing = !this.location;
                    }
                }
            },
            timeZoneOffset: $rootScope.isOrs ? 1750 : undefined,
            validate: function () {
                validateStop(this);
            },
            hasError: function () {
                return this.errors.timeZoneIsRequired
                    || this.errors.departureIsBeforeArrival
                    || (this.type === 'address' && this.address.hasError())
                    || (this.type === 'geofence' && this.geofence.hasError());
            }
        };
    }

    function removeStop(stopId) {

        if (!service.canEdit) {
            service.feedback.addError('Shipment can not be changed');
            return null;
        }

        const stops = [];
        _.forEach(service.stops.other, function (stop) {
            if (stop.stopId !== stopId) {
                stops.push(stop);
            }
        });
        service.stops.other = stops;
    }

    function reset() {
        nextStopId = 0;

        //problem if the trackingConfig has not finished loading
        service.referencePrefix = SentinelUiSession.focus.trackingConfig && SentinelUiSession.focus.trackingConfig.referencePrefix ? SentinelUiSession.focus.trackingConfig.referencePrefix : null;
        service.referenceNumber.value = null;
        service.referenceNumber.isPristine = true;
        service.referenceNumber.errors.isBlank = true;
        service.referenceNumber.isDuplicate = false;
        service.referenceNumber.isWrongPrefix = false;
        service.endDate.value = moment().add(1, 'day').toDate(),
        service.endDate.isPristine = true;
        service.endDate.errors.isBeforeNow = false;
        service.endDate.errors.isBlank = false;
        service.endDate.errors.isNotADate = false;
        service.endDate.errors.isNotATime = false;
        service.endDate.errors.isTimeBlank = false;
        service.beginDate.value = moment().toDate(),
        service.beginDate.isPristine = true;
        service.beginDate.errors.isBeforeNow = false;
        service.beginDate.errors.isBlank = false;
        service.beginDate.errors.isNotADate = false;
        service.beginDate.errors.isNotATime = false;
        service.beginDate.errors.isTimeBlank = false;
        service.device.value = null;
        service.device.isPristine = true;
        service.device.errors.isBlank = true;
        service.device.errors.isNotFound = false;
        service.sentinels = [];
        service.trackDeviceReturn.value = false;
        //service.trackDeviceReturn.isPristine = true;
        service.subscribers = [];
        service.shipmentEmails.value = null;
        service.shipmentEmails.isPristine = true;
        service.shipmentEmails.errors.invalidEmails = [];
        service.stops.origin = newEditorStop('address');
        service.stops.other = [];
        service.stops.destination = newEditorStop('address');
        service.notes.value = null;
        service.arrivalLightLevel.value = null;
        service.completionDelay.value = null;
        service.canEdit = true;

        if (service.shipment === null) {
            return;
        }

        service.referenceNumber.value = SentinelUiSession.focus.trackingConfig && SentinelUiSession.focus.trackingConfig.referencePrefix ?
            service.shipment.shipmentInfo.referenceNumber.replace(SentinelUiSession.focus.trackingConfig.referencePrefix, '') :
            service.shipment.shipmentInfo.referenceNumber;
        service.device.value = service.shipmentDevice;

        const beginDateMoment = moment(service.shipment.shipmentInfo.startDate).local();
        service.beginDate.value = beginDateMoment.toDate();
        const endDateMoment = moment(service.shipment.shipmentInfo.endDate).local();
        service.endDate.value = endDateMoment.toDate();
        service.trackDeviceReturn.value = service.shipment.shipmentInfo.trackReverseLogistics;
        service.subscribers = service.shipment.shipmentInfo.subscribers !== null ? service.shipment.shipmentInfo.subscribers : [];
        service.shipmentEmails.value = service.shipment.shipmentInfo.shipmentEmails !== null ? joinEmails(service.shipment.shipmentInfo.shipmentEmails) : null;
        service.notes.value = service.shipment.shipmentInfo.notes;
        service.canEdit = service.shipment.shipmentInfo.status.toLowerCase() !== 'complete' && service.shipment.shipmentInfo.status.toLowerCase() !== 'cancelled' && service.shipment.shipmentInfo.status.toLowerCase() !== 'expired';

        service.sentinels = service.shipment.shipmentInfo.sentinels;
        service.beginTrackingStrategyType.value = service.shipment.shipmentInfo.beginTrackingStrategyType;
        service.endTrackingStrategyType.value = service.shipment.shipmentInfo.endTrackingStrategyType;

        if (service.beginTrackingStrategyType.value === "ArrivalGeofence") {
            loadGeofence(service.shipment.shipmentInfo.beginTrackingArrivalGeofenceId, "begin");
        } else if (service.beginTrackingStrategyType.value === "DepartureGeofence") {
            loadGeofence(service.shipment.shipmentInfo.beginTrackingDepartureGeofenceId, "begin");
        }

        if (service.endTrackingStrategyType.value === "ArrivalGeofence") {
            loadGeofence(service.shipment.shipmentInfo.endTrackingArrivalGeofenceId, "end");
        }

        if (service.endTrackingStrategyType.value === "ArrivalMultiple") {
            if (service.shipment.shipmentInfo.endTrackingArrivalGeofenceId) {
                loadGeofence(service.shipment.shipmentInfo.endTrackingArrivalGeofenceId, "end");
            }
            if (service.shipment.shipmentInfo.endTrackingArrivalEvents && service.shipment.shipmentInfo.endTrackingArrivalEvents.length > 0) {
                const lightEvent = service.shipment.shipmentInfo.endTrackingArrivalEvents.find(
                    e => e.propertyName === 'LightValue' && e.operator === '>'
                );
                if (lightEvent) {
                    service.arrivalLightLevel.value = lightEvent.value;
                }
            }
            if (service.shipment.shipmentInfo.endTrackingArrivalTimeDelayMinutes) {
                service.completionDelay.value = service.shipment.shipmentInfo.endTrackingArrivalTimeDelayMinutes;
            }
        }

        _.forEach(service.shipment.shipmentStops, function (shipmentStop, index) {
            if (index === 0) {
                service.stops.origin = editorStop(shipmentStop);
                return;
            }
            if (index === service.shipment.shipmentStops.length - 1) {
                service.stops.destination = editorStop(shipmentStop);
                return;
            }

            const otherStop = editorStop(shipmentStop);
            otherStop.stopId = nextStopId++;
            service.stops.other.push(otherStop);
        });
    }

    function joinEmails(emailsArray) {
        console.log("joinEmails", emailsArray);
        if (emailsArray.length === 0)
            return "";
        let emails = "";
        emailsArray.forEach(item => emails += item.email + ",");
        console.log("emails", emails);
        return emails.substring(0, emails.length - 1);
    }

    function buildArrayEmails(emailsArraySource) {
        if (!emailsArraySource)
            return [];
        const emails = [];
        emailsArraySource.forEach(item => emails.push({ "email": item }));
        return emails;
    }

    function saveNewStops(shipment) {
        const stops = [];
        _.forEach(service.stops.other, function (stop) {
            stops.push(stopToSave(stop));
        });
        return ShipmentsService.addStops(shipment.shipmentInfo.shipmentId, stops);
    }

    function saveNew() {
        service.feedback.clear();
        validate();

        if (!service.isValid) {
            return null;
        }

        const shipmentEmails = Array.isArray(service.shipmentEmails.value)
            ? service.shipmentEmails.value.map(email => ({ email }))
            : service.shipmentEmails.value
                ? buildArrayEmails(service.shipmentEmails.value.replace(/\s/g, '').split(";").join(",").split(','))
                : [];

        const imei = service.device.value?.imei ?? service.shipment.shipmentInfo.deviceTagId;

        const shipmentToSave = {
            shipmentInfo: {
                clientGuid: SentinelUiSession.focus.id,
                referenceNumber: SentinelUiSession.focus.trackingConfig && SentinelUiSession.focus.trackingConfig.referencePrefix ?
                    SentinelUiSession.focus.trackingConfig.referencePrefix + service.referenceNumber.value :
                    service.referenceNumber.value,
                deviceTagId: imei,
                startDate: moment().utc().format(),
                sentinels: [],
                endDate: moment(service.endDate.value).utc().format(),
                notes: service.notes.value,
                subscribers: service.subscribers,
                shipmentEmails,
                trackReverseLogistics: service.trackDeviceReturn.value
            },
            shipmentStops: []
        };

        _.forEach(service.sentinels, function (s) {
            shipmentToSave.shipmentInfo.sentinels.push({
                "sentinelId": s.sentinelId,
                "deviceId": s.deviceId
            });
        });

        shipmentToSave.shipmentInfo.beginTrackingStrategyType = service.beginTrackingStrategyType.value;
        shipmentToSave.shipmentInfo.endTrackingStrategyType = service.endTrackingStrategyType.value;

        if (service.beginTrackingStrategyType.value === "date/time") {
            shipmentToSave.shipmentInfo.startDate = moment(service.beginDate.value).utc().format();
        } else if (service.beginTrackingStrategyType.value === "ArrivalGeofence") {
            shipmentToSave.shipmentInfo.beginTrackingArrivalGeofenceId = service.endGeofence.value.id;
        } else if (service.beginTrackingStrategyType.value === "DepartureGeofence") {
            shipmentToSave.shipmentInfo.beginTrackingDepartureGeofenceId = service.beginGeofence.value.id;
        } else if (service.beginTrackingStrategyType.value === "Immediately") {
            //Nothing
        }

        if (service.endTrackingStrategyType.value === "date/time") {
            shipmentToSave.shipmentInfo.endDate = moment(service.endDate.value).utc().format();
        } else if (service.endTrackingStrategyType.value === "ArrivalGeofence") {
            shipmentToSave.shipmentInfo.endTrackingArrivalGeofenceId = service.endGeofence2.value.id;
        } else if (service.endTrackingStrategyType.value === "ArrivalMultiple") {
            if (service.endGeofence2.value) {
                shipmentToSave.shipmentInfo.endTrackingArrivalGeofenceId = service.endGeofence2.value.id;
            }
            if (service.arrivalLightLevel.value !== null) {
                shipmentToSave.shipmentInfo.endTrackingArrivalEvents = [
                    {
                        propertyName: "LightValue",
                        operator: ">",
                        value: service.arrivalLightLevel.value
                    }
                ];
            }
            if (service.completionDelay.value !== null) {
                shipmentToSave.shipmentInfo.endTrackingArrivalTimeDelayMinutes = service.completionDelay.value;
            }
        }

        console.log(shipmentToSave);
        return ShipmentsService.createShipment(shipmentToSave);
    }

    function preSaveShipmentInfo() {
        service.feedback.clear();

        if (!service.canEdit) {
            service.feedback.addError('Shipment can not be changed');
            return null;
        }

        validateShipmentInfo();

        if (!service.isValidInfo) {
            return null;
        }

        const shipmentEmails = Array.isArray(service.shipmentEmails.value)
            ? service.shipmentEmails.value.map(email => ({ email }))
            : service.shipmentEmails.value
                ? buildArrayEmails(service.shipmentEmails.value.replace(/\s/g, '').split(";").join(",").split(','))
                : [];

        const imei = service.device.value?.imei ?? service.shipment.shipmentInfo.deviceTagId;

        const shipmentInfoToSave = {
            shipmentId: service.shipment.shipmentInfo.shipmentId,
            clientGuid: service.shipment.shipmentInfo.clientGuid,
            referenceNumber: SentinelUiSession.focus.trackingConfig && SentinelUiSession.focus.trackingConfig.referencePrefix ?
                SentinelUiSession.focus.trackingConfig.referencePrefix + service.referenceNumber.value :
                service.referenceNumber.value,
            deviceTagId: imei,
            startDate: moment().utc().format(),
            sentinels: [],
            status: "",
            endDate: moment(service.endDate.value).utc().format(),
            notes: service.notes.value,
            subscribers: service.subscribers,
            shipmentEmails,
            trackReverseLogistics: service.trackDeviceReturn.value
        };

        _.forEach(service.sentinels, function (s) {
            shipmentInfoToSave.sentinels.push({
                "sentinelId": s.sentinelId,
                "deviceId": s.deviceId
            });
        });

        shipmentInfoToSave.beginTrackingStrategyType = service.beginTrackingStrategyType.value;
        shipmentInfoToSave.endTrackingStrategyType = service.endTrackingStrategyType.value;

        if (service.beginTrackingStrategyType.value === "date/time") {
            shipmentInfoToSave.startDate = moment(service.beginDate.value).utc().format();
        } else if (service.beginTrackingStrategyType.value === "ArrivalGeofence") {
            shipmentInfoToSave.beginTrackingArrivalGeofenceId = service.endGeofence.value.id;
        } else if (service.beginTrackingStrategyType.value === "DepartureGeofence") {
            shipmentInfoToSave.beginTrackingDepartureGeofenceId = service.beginGeofence.value.id;
        } else if (service.beginTrackingStrategyType.value === "Immediately") {
            //Nothing
            if (service.beginTrackingStrategyTypeOld === "Immediately")
                shipmentInfoToSave.startDate = moment(service.beginDate.value).utc().format();
        }

        if (service.endTrackingStrategyType.value === "date/time") {
            shipmentInfoToSave.endDate = moment(service.endDate.value).utc().format();
        } else if (service.endTrackingStrategyType.value === "ArrivalGeofence") {
            shipmentInfoToSave.endTrackingArrivalGeofenceId = service.endGeofence2.value.id;
        } else if (service.endTrackingStrategyType.value === "ArrivalMultiple") {
            if (service.endGeofence2.value) {
                shipmentInfoToSave.endTrackingArrivalGeofenceId = service.endGeofence2.value.id;
            }
            if (service.arrivalLightLevel.value !== null) {
                shipmentInfoToSave.endTrackingArrivalEvents = [
                    {
                        propertyName: "LightValue",
                        operator: ">",
                        value: service.arrivalLightLevel.value
                    }
                ];
            }
            if (service.completionDelay.value !== null) {
                shipmentInfoToSave.endTrackingArrivalTimeDelayMinutes = service.completionDelay.value;
            }
        }

        const now = moment();

        //Reset status to Pending when the current status is Overdue and beginTrackingStrategyType is
        //Immediately
        if (service.beginTrackingStrategyTypeOld != "Immediately" &&
            service.beginTrackingStrategyType.value === "Immediately" &&
            service.shipment.shipmentInfo.status === "Overdue") {
            shipmentInfoToSave.status = "Pending";
        }
            //Reset status to Pending when the current status is Overdue and beginTrackingStrategyType is
        //date/time and the startDate is in the future date
        else if (service.beginTrackingStrategyType.value === "date/time" &&
            service.shipment.shipmentInfo.status === "Overdue" &&
            moment(shipmentInfoToSave.startDate).isSameOrAfter(now)) {
            shipmentInfoToSave.status = "Pending";
            //Reset status to Pending when the current status is Overdue and endTrackingStrategyType is
            //date/time and the endDate is in the future date
        } else if (service.endTrackingStrategyType.value === "date/time" &&
            service.shipment.shipmentInfo.status === "Overdue") {
            const durationInMinutes = now.diff(moment(shipmentInfoToSave.endDate), 'minutes', true);
            console.log("durationInMinutes", durationInMinutes);
            if (durationInMinutes >= 0 && durationInMinutes < 1) {
                console.log("Complete");
                shipmentInfoToSave.status = "Complete";
            } else if (durationInMinutes >= 1) {
                console.log("Pending");
                shipmentInfoToSave.status = "Pending";
            }

        }

        return shipmentInfoToSave;
    }

    function saveShipmentInfo() {

        const shipmentInfoToSave = preSaveShipmentInfo();

        if (!shipmentInfoToSave)
            return null;

        return ShipmentsService.updateInfo(shipmentInfoToSave);
    }

    function stopsHaveError() {

        let otherError = false;
        _.forEach(service.stops.other, function (stop) {
            otherError = stop.hasError() || otherError;
        });
        return /*service.stops.origin.hasError() || service.stops.destination.hasError() ||*/ otherError;
    }

    function toUtc(date, timeZone) {
        const localDate = moment.tz(date, timeZone);
        const utcDate = localDate.utc().format();
        return utcDate;
    }

    function stopToSave(editorStop) {
        const shipmentStop = {
            destinationId: 0,
            nameForAddress: null,
            address: null,
            addressLongitude: null,
            addressLatitude: null,
            shipmentStopPolygonGeofence: null,
            shipmentStopRadialGeofence: null,
            hasArrived: false,
            timeZoneOffset: undefined,
            expectedArrivalDateTime: undefined,
            expectedDepartureDateTime: undefined
        };
        if (editorStop.type === 'address') {
            shipmentStop.nameForAddress = editorStop.address.value;
            shipmentStop.address = editorStop.address.value;
            shipmentStop.addressLongitude = editorStop.locationSearch.location?.geometry.location.lng() ?? editorStop.addressLongitude;
            shipmentStop.addressLatitude = editorStop.locationSearch.location?.geometry.location.lat() ?? editorStop.addressLatitude;
            shipmentStop.shipmentStopRadialGeofence = {
                longitudeCenter: editorStop.locationSearch.location?.geometry.location.lng() ?? editorStop.addressLongitude,
                latitudeCenter: editorStop.locationSearch.location?.geometry.location.lat() ?? editorStop.addressLatitude,
                radiusUnitType: 'miles',
                radius: 2.0
            };
        } else {
            shipmentStop.nameForAddress = editorStop.geofence.value.name;
            shipmentStop.address = editorStop.geofence.value.address;

            if (editorStop.geofence.value.type === 'polygon') {
                const coords = editorStop.geofence.value.shapeText.replace('POLYGON ((', '').replace('))', '').split(', ');
                const latLngBounds = new google.maps.LatLngBounds();
                _.forEach(coords, function (coord) {
                    const point = coord.split(' ');
                    const latLng = new google.maps.LatLng(Number(point[1]), Number(point[0]));
                    latLngBounds.extend(latLng);
                });
                const center = latLngBounds.getCenter();

                shipmentStop.addressLongitude = center.lng();
                shipmentStop.addressLatitude = center.lat();
                shipmentStop.shipmentStopPolygonGeofence = {
                    shapeText: editorStop.geofence.value.shapeText
                };
            } else {
                shipmentStop.addressLongitude = editorStop.geofence.value.longitudeCenter;
                shipmentStop.addressLatitude = editorStop.geofence.value.latitudeCenter;
                shipmentStop.shipmentStopRadialGeofence = {
                    longitudeCenter: editorStop.geofence.value.longitudeCenter,
                    latitudeCenter: editorStop.geofence.value.latitudeCenter,
                    radiusUnitType: editorStop.geofence.value.radiusUnitType,
                    radius: editorStop.geofence.value.radius
                };
            }
        }

        if (editorStop.destinationId) {
            shipmentStop.destinationId = editorStop.destinationId;
        }

        if (editorStop.expectedArrivalDateTime || editorStop.expectedDepartureDateTime) {
            shipmentStop.timeZoneOffset = editorStop.timeZoneOffset;
            const timeZone = TimeZoneList.find(tz => tz.id === Number(shipmentStop.timeZoneOffset));
            if (!timeZone) {
                // could not find the time zone for the id
                throw new Error(`Error: Could not find time zone ${shipmentStop.timeZoneOffset}.`);
            }
            shipmentStop.expectedArrivalDateTime = editorStop.expectedArrivalDateTime ? toUtc(editorStop.expectedArrivalDateTime, timeZone.name) : undefined;
            shipmentStop.expectedDepartureDateTime = editorStop.expectedDepartureDateTime ? toUtc(editorStop.expectedDepartureDateTime, timeZone.name) : undefined;
        } else if ($rootScope.isOrs ) {
            shipmentStop.timeZoneOffset = 1750;
        }

        return shipmentStop;
    }

    function validate() {
        service.isValid = true;
        validateShipmentInfo();
        validateStops();
        validateSubscribers();
        service.isValid = service.isValidInfo && !service.shipmentEmails.hasError();
    }

    function validateDevice() {
        service.device.isPristine = false;
        service.device.errors.isNotFound = false;
        service.device.errors.isBlank = service.device.value === null;
        if (service.device.errors.isBlank) {
            return;
        }

        const imei = service.device.value?.imei ?? service.shipment.shipmentInfo.deviceTagId;
        const promise = SentryAccountApiService.getDevicesForASML(SentinelUiSession.focus, imei).$promise;
        promise.then(
            function (result) {
                if (result.length === 0) {
                    service.device.errors.isNotFound = true;
                }
            },
            function (error) {
                service.device.errors.isNotFound = true;
            }
        );
    }

    function validateBeginGeofence() {
        service.beginGeofence.isPristine = false;
        service.beginGeofence.errors.isNotFound = false;
        service.beginGeofence.errors.isBlank = service.beginGeofence.value === null;

        //TODO get from endpoint the geofence to validate if exists
    }

    function validateEndGeofence() {
        service.endGeofence.isPristine = false;
        service.endGeofence.errors.isNotFound = false;
        service.endGeofence.errors.isBlank = service.endGeofence.value === null;

        //TODO get from endpoint the geofence to validate if exists
    }

    function validateEndGeofence2() {
        service.endGeofence2.isPristine = false;
        service.endGeofence2.errors.isNotFound = false;

        if (service.endTrackingStrategyType.value === "ArrivalMultiple") {
            service.endGeofence2.errors.isBlank = false;
            const bothMissing = service.endGeofence2.value === null && service.arrivalLightLevel.value === null;
            service.endGeofence2.errors.thisOrLight = bothMissing;
            service.arrivalLightLevel.errors.thisOrGeofence = bothMissing;
        } else {
            service.endGeofence2.errors.thisOrLight = false;
            service.arrivalLightLevel.errors.thisOrGeofence = false;
            service.endGeofence2.errors.isBlank = service.endGeofence2.value === null;
        }

        //TODO get from endpoint the geofence to validate if exists
    }

    function validateArrivalLightLevel() {
        if (service.arrivalLightLevel.value === null) {
            service.arrivalLightLevel.errors.belowMin = false;
            service.arrivalLightLevel.errors.aboveMax = false;
            return;
        }
        service.arrivalLightLevel.errors.thisOrGeofence = false;
        service.endGeofence2.errors.thisOrLight = false;
        service.endGeofence2.errors.isBlank = false;
        if (service.arrivalLightLevel.value < service.arrivalLightLevel.min) {
            service.arrivalLightLevel.errors.belowMin = true;
            return;
        }
        if (service.arrivalLightLevel.value > service.arrivalLightLevel.max) {
            service.arrivalLightLevel.errors.aboveMax = true;
            return;
        }
        service.arrivalLightLevel.errors.belowMin = false;
        service.arrivalLightLevel.errors.aboveMax = false;
    }

    function validateEndDate() {
        service.endDate.errors.isBlank = false;
        service.endDate.errors.isNotADate = false;
        service.endDate.errors.isBeforeNow = false;
        service.endDate.isPristine = false;

        service.endDate.errors.isBlank = service.endDate.value === undefined || service.endDate.value === null;
        if (service.endDate.errors.isBlank) {
            return;
        }

        const endDateTimeMoment = DatetimeValidatorService.toMomentDt(service.endDate.value);
        if (!endDateTimeMoment) {
            service.endDate.errors.isNotADate = DatetimeValidatorService.dateError;
        }

        console.log("now", moment());
        console.log("endDateTimeMoment", endDateTimeMoment);

        const diffInMinutes = moment(endDateTimeMoment).diff(moment(), 'minutes', true);

        console.log("diffInMinutes", diffInMinutes);

        service.endDate.errors.isBeforeNow = diffInMinutes < -1; //endDateTimeMoment.isBefore(moment());
    }

    function validateBeginDate() {
        service.beginDate.errors.isBlank = false;
        service.beginDate.errors.isNotADate = false;
        service.beginDate.errors.isBeforeNow = false;
        service.beginDate.isPristine = false;

        service.beginDate.errors.isBlank = service.beginDate.value === undefined || service.beginDate.value === null;
        if (service.beginDate.errors.isBlank) {
            return;
        }

        const beginDateTimeMoment = DatetimeValidatorService.toMomentDt(service.beginDate.value);
        if (!beginDateTimeMoment) {
            service.beginDate.errors.isNotADate = DatetimeValidatorService.dateError;
        }
    }

    function validateReferenceNumber() {
        service.referenceNumber.isPristine = false;
        service.referenceNumber.errors.isBlank = true;
        service.referenceNumber.errors.isDuplicate = false;
        service.referenceNumber.errors.isWrongPrefix = false;
        service.referenceNumber.errors.isTooLong = false;
        service.referenceNumber.errors.hasInvalidCharacters = false;

        //validate a value exists
        service.referenceNumber.errors.isBlank = !service.referenceNumber.value || service.referenceNumber.value.trim() === '';
        if (service.referenceNumber.errors.isBlank) {
            return;
        }

        service.referenceNumber.errors.isTooLong = (SentinelUiSession.focus.trackingConfig && SentinelUiSession.focus.trackingConfig.referencePrefix && SentinelUiSession.focus.trackingConfig.referencePrefix.length + service.referenceNumber.value.length > 80) || service.referenceNumber.value.length > 80;
        if (service.referenceNumber.errors.isTooLong) {
            return;
        }

        //validate reference number is unique
        const referenceNumberToCheck = SentinelUiSession.focus.trackingConfig && SentinelUiSession.focus.trackingConfig.referencePrefix ?
            SentinelUiSession.focus.trackingConfig.referencePrefix + service.referenceNumber.value :
            service.referenceNumber.value;
        const promise = ShipmentsService.getShipmentByRef(SentinelUiSession.focus, referenceNumberToCheck).$promise;
        promise.then(
            function (result) {
                if (service.shipment !== null && service.shipment.shipmentInfo.shipmentId === result.shipmentInfo.shipmentId) {
                    return;
                }

                if (result.shipmentInfo.status.toLowerCase() !== 'cancelled') {
                    service.referenceNumber.errors.isDuplicate = true;
                }
            },
            function (error) {
                if (error.status === 404) {
                    //we expect this to occur, so ignore it
                }
            }
        );
    }

    function validateShipmentInfo() {
        service.isValidInfo = true;
        validateReferenceNumber();
        validateDevice();
        validateSubscribers();

        let beginDateHasError = false;
        let endDateHasError = false;
        let beginGeofenceHasError = false;
        let endGeofenceHasError = false;
        let endGeofence2HasError = false;
        let arrivalLightLevelError = false;
        let completionDelayError = false;

        if (service.beginTrackingStrategyType.value === "date/time") {
            validateBeginDate();
            beginDateHasError = service.beginDate.hasError();
        }

        if (service.endTrackingStrategyType.value === "date/time") {
            validateEndDate();
            endDateHasError = service.endDate.hasError();
        }

        if (service.beginTrackingStrategyType.value === "ArrivalGeofence") {
            validateEndGeofence();
            endGeofenceHasError = service.endGeofence.hasError();

        } else if (service.beginTrackingStrategyType.value === "DepartureGeofence") {
            validateBeginGeofence();
            beginGeofenceHasError = service.beginGeofence.hasError();
        }

        if (service.endTrackingStrategyType.value === "ArrivalGeofence") {
            validateEndGeofence2();
            endGeofence2HasError = service.endGeofence2.hasError();
        }

        if (service.endTrackingStrategyType.value === "ArrivalMultiple") {
            validateEndGeofence2();
            endGeofence2HasError = service.endGeofence2.hasError();
            arrivalLightLevelError = service.arrivalLightLevel.hasError();
            completionDelayError = service.completionDelay.hasError();
        }

        service.isValidInfo = !(service.referenceNumber.hasError() ||
            service.device.hasError() ||
            endDateHasError ||
            beginDateHasError ||
            beginGeofenceHasError ||
            endGeofenceHasError ||
            endGeofence2HasError ||
            arrivalLightLevelError ||
            completionDelayError ||
            service.shipmentEmails.hasError());
    }

    function validateStop(stop) {
        if (stop.type === 'address') {
            stop.address.validate();
            stop.locationSearch.validate();
        }

        if (stop.type === 'geofence') {
            stop.geofence.validate();
        }
        stop.errors = {
            timeZoneIsRequired: false,
            departureIsBeforeArrival: false
        };
        if ($rootScope.isOrs) {
            stop.timeZoneOffset = 1750;
        }
        if (stop.expectedArrivalDateTime || stop.expectedDepartureDateTime) {
            // must have a selected time zone
            stop.errors.timeZoneIsRequired = !stop.timeZoneOffset;
            if (stop.expectedArrivalDateTime && stop.expectedDepartureDateTime) {
                // departure time must be after arrival time
                stop.errors.departureIsBeforeArrival = stop.expectedDepartureDateTime < stop.expectedArrivalDateTime;
            }
        }

        // todo? validate date/times across multiple stops
    }

    function validateStops() {
        for (const stop of service.stops.other) {
            validateStop(stop);
        }
        // todo: validate stop round trip
    }

    function completeShipment() {
        if (!service.canEdit) {
            service.feedback.addError('Shipment can not be changed');
            return null;
        }
        vm.feedback.clear();

        const promise = ShipmentsService.completeShipment(service.shipment.shipmentInfo.shipmentId).$promise;
        promise.then(
            function (result) {
                const referenceNumber = service.referencePrefix ? service.referencePrefix + service.referenceNumber.value : service.referenceNumber.value;
                service.feedback.addSuccess('Shipment ' + referenceNumber + ' has been completed manually');
                service.shipment.shipmentInfo.status = 'Completed';
                service.canEdit = false;
            },
            function (error) {
                service.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function validateSubscribers() {
        service.shipmentEmails.isPristine = false;
        service.shipmentEmails.isValid = true;
        service.shipmentEmails.errors.invalidEmails = [];

        if (!service.shipmentEmails.value || (service.shipmentEmails.value.trim && service.shipmentEmails.value.trim() === '')) {
            return;
        }

        const emails = Array.isArray(service.shipmentEmails.value)
            ? service.shipmentEmails.value
            : service.shipmentEmails.value.replace(/\s/g, '').split(";").join(",").split(',');

        _.forEach(emails, function (email) {
            if (email !== null && email.trim() !== '') {
                console.log("email to validate", email.trim());
                const isEmail = emailValidator.test(email.trim());
                console.log("isEmail", isEmail);
                if (!isEmail) {
                    service.shipmentEmails.errors.invalidEmails.push(email.trim());
                }
            }
        });
    }
}
