import angular from "angular";

angular
    .module('api-sentinel')
    .constant('SENTINEL_API_HOST_CONSTANTS', {
        'URL': 'https://oainsight-api.azurewebsites.net',
        'VISION_URL': 'https://oainsight-angular.azurewebsites.net/track/',
        'ERRORS': {
            'AUTHENTICATION_REQUIRED': 'AUTHENTICATION_REQUIRED'
        },
        'USER_ROLES': {
            none: 'api-none',
            all: 'api-all',
            apiAdmin: 'api-admin',
            apiAccount: 'api-account'
        }
    });
