import angular from "angular";
import moment from "moment/moment";

const datetimeValidatorService = () => {
    const _24HoursMoment = 'H:mm';
    const _12HoursMoment = 'h:mm A';

    const service = {
        dateError: null
    };

    const localTimeFormatMoment = () => {
        const now = moment().format('LT');
        return now.toLowerCase().indexOf('m') > -1
            ? _12HoursMoment
            : _24HoursMoment;
    };

    service.toMomentDt = (dateTime) => {

        if (!dateTime) {
            service.dateError = 'date is required';
            return null;
        }

        if (!moment(dateTime).isValid()) {
            service.dateError = 'date is not a valid date';
            return null;
        }

        return moment(dateTime, "L " + localTimeFormatMoment());
    }

    return service;
}

angular.module('api-common').factory('DatetimeValidatorService', datetimeValidatorService);
