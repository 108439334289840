import angular from "angular";

angular
    .module('ui-sentinel.shipments.notificationsAdmin')
    .controller('NotificationsAdminController', NotificationsAdminController);

NotificationsAdminController.$inject = ['$rootScope', 'SentinelUiSession', 'FeedbackService', 'ShipmentNotificationsService', 'HideShipmentStopTimes'];

function NotificationsAdminController($rootScope, SentinelUiSession, FeedbackService, ShipmentNotificationsService, HideShipmentStopTimes) {

    // NOTE: proper camelcase is avoided here to support dynamic allocation based upon notification objects
    var vm = {
        hideShipmentStopTimes: HideShipmentStopTimes,
        stoparrival: null,
        stopdelayedarrival: null,
        shipmentcompleted: null,
        shipmentcreated: null,
        stopdeparture: null,
        stopdelayeddeparture: null,
        shipmentoverdue: null,
        viewalarm: null,
        subscribers: [],
        feedback: FeedbackService,
        isOrs: $rootScope.isOrs,
        hasPermission: {
            toChange: false
        },
        isDisabled: (option, emailAddress) => {
            if (!vm.hasPermission.toChange || !option?.isActiveForNotificationSubscribers) {
                return true;
            }
            if ($rootScope.isOrs && SentinelUiSession.user.isAccountEditor) {
                // ors account editors can only edit their own notifications
                if (emailAddress !== SentinelUiSession.user.name) {
                    return true;
                }
            }
            return false;
        },
        actions: {
            subscriber: {
                toggleCompletedForSubscriber: toggleCompletedForSubscriber,
                toggleCreatedForSubscriber: toggleCreatedForSubscriber,
                toggleOverdueForSubscriber: toggleOverdueForSubscriber,
                toggleArrivalForSubscriber: toggleArrivalForSubscriber,
                toggleDelayedArrivalForSubscriber: toggleDelayedArrivalForSubscriber,
                toggleDepartureForSubscriber: toggleDepartureForSubscriber,
                toggleDelayedDepartureForSubscriber: toggleDelayedDepartureForSubscriber,
                toggleViewAlarmForSubscriber: toggleViewAlarmForSubscriber,
            },
            notification: {
                toggleArrivalNotificationSubscribers: toggleArrivalNotificationSubscribers,
                toggleDelayedArrivalNotificationSubscribers: toggleDelayedArrivalNotificationSubscribers,
                toggleArrivalShipmentSubscribers: toggleArrivalShipmentSubscribers,
                toggleDelayedArrivalShipmentSubscribers: toggleDelayedArrivalShipmentSubscribers,
                toggleCompletedNotificationSubscribers: toggleCompletedNotificationSubscribers,
                toggleCompletedShipmentSubscribers: toggleCompletedShipmentSubscribers,
                toggleCreatedNotificationSubscribers: toggleCreatedNotificationSubscribers,
                toggleDepartureNotificationSubscribers: toggleDepartureNotificationSubscribers,
                toggleDelayedDepartureNotificationSubscribers: toggleDelayedDepartureNotificationSubscribers,
                toggleDepartureShipmentSubscribers: toggleDepartureShipmentSubscribers,
                toggleDelayedDepartureShipmentSubscribers: toggleDelayedDepartureShipmentSubscribers,
                toggleOverdueNotificationSubscribers: toggleOverdueNotificationSubscribers,
                toggleViewAlarmNotificationSubscribers: toggleViewAlarmNotificationSubscribers,
                toggleViewAlarmShipmentSubscribers: toggleViewAlarmShipmentSubscribers,
            }
        },
        propertyName: ['lastName', 'firstName'],
        reverse: false,
        sortBy: sortBy,
    };
    activate();
    return vm;

    function activate() {
        $rootScope.$on('CLIENT_FOCUS_CHANGED', function (event, args) {
            if ($rootScope.$state.current.name === 'shipmentnotifications.admin') {
                load();
            }
        });

        setPermissions();
        load();
    }

    function load() {
        vm.stoparrival = null;
        vm.stopdelayedarrival = null;
        vm.shipmentcompleted = null;
        vm.shipmentcreated = null;
        vm.stopdeparture = null;
        vm.stopdelayeddeparture = null;
        vm.shipmentoverdue = null;
        vm.viewalarm = null;
        vm.subscribers = [];

        $rootScope.loading = true;

        var promise = ShipmentNotificationsService.getNotifications(SentinelUiSession.focus).$promise;
        promise.then(
            function (result) {
                _.forEach(result, function (notification) {
                    if (notification.notificationType)
                        vm[notification.notificationType.toLowerCase()] = notification;
                });
                loadSubscribers();
            },
            function (error) {
                console.log('err', error);
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function loadSubscribers() {
        vm.subscribers = [];
        var contactsPromise = ShipmentNotificationsService.getContacts(SentinelUiSession.focus).$promise;
        contactsPromise.then(
            function (result) {
                _.forEach(result, function (subscriber) {
                    console.log(subscriber, "iSaC");
                    subscriber = angular.extend(subscriber, {
                        isStopArrivalOn: subscriber.notifications.indexOf('StopArrival') > -1,
                        isStopDelayedArrivalOn: subscriber.notifications.indexOf('StopDelayedArrival') > -1,
                        isStopDepartureOn: subscriber.notifications.indexOf('StopDeparture') > -1,
                        isStopDelayedDepartureOn: subscriber.notifications.indexOf('StopDelayedDeparture') > -1,
                        isShipmentCompletedOn: subscriber.notifications.indexOf('ShipmentCompleted') > -1,
                        isShipmentCreatedOn: subscriber.notifications.indexOf('ShipmentCreated') > -1,
                        isShipmentOverdueOn: subscriber.notifications.indexOf('ShipmentOverdue') > -1,
                        isViewAlarmOn: subscriber.notifications.indexOf('ViewAlarm') > -1
                    });

                    vm.subscribers.push(subscriber);
                });
            },
            function (error) {
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        ).finally(function () {
            $rootScope.loading = false;
        });
    }

    function setPermissions() {
        vm.hasPermission.toChange =
            SentinelUiSession.user.isSystemAdmin ||
            SentinelUiSession.user.isAccountAdmin ||
            SentinelUiSession.user.isAccountEditor;
    }

    function toggleArrivalNotificationSubscribers() {
        $('#btn-arrival-notification-subscribers').blur();
        vm.stoparrival.isActiveForNotificationSubscribers = !vm.stoparrival.isActiveForNotificationSubscribers;
        var promise = ShipmentNotificationsService.updateArrival(SentinelUiSession.focus, vm.stoparrival).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.stoparrival = result;
            },
            function (error) {
                vm.stoparrival.isActiveForNotificationSubscribers = !vm.stoparrival.isActiveForNotificationSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleDelayedArrivalNotificationSubscribers() {
        $('#btn-delayed-arrival-notification-subscribers').blur();
        vm.stopdelayedarrival.isActiveForNotificationSubscribers = !vm.stopdelayedarrival.isActiveForNotificationSubscribers;
        var promise = ShipmentNotificationsService.updateDelayedArrival(SentinelUiSession.focus, vm.stopdelayedarrival).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.stopdelayedarrival = result;
            },
            function (error) {
                vm.stopdelayedarrival.isActiveForNotificationSubscribers = !vm.stopdelayedarrival.isActiveForNotificationSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleArrivalShipmentSubscribers() {
        $('#btn-arrival-shipment-subscribers').blur();
        vm.stoparrival.isActiveForShipmentSubscribers = !vm.stoparrival.isActiveForShipmentSubscribers;
        var promise = ShipmentNotificationsService.updateArrival(SentinelUiSession.focus, vm.stoparrival).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.stoparrival = result;
            },
            function (error) {
                vm.stoparrival.isActiveForShipmentSubscribers = !vm.stoparrival.isActiveForShipmentSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }


    function toggleDelayedArrivalShipmentSubscribers() {
        $('#btn-delayed-arrival-shipment-subscribers').blur();
        vm.stopdelayedarrival.isActiveForShipmentSubscribers = !vm.stopdelayedarrival.isActiveForShipmentSubscribers;
        var promise = ShipmentNotificationsService.updateDelayedArrival(SentinelUiSession.focus, vm.stopdelayedarrival).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.stopdelayedarrival = result;
            },
            function (error) {
                vm.stopdelayedarrival.isActiveForShipmentSubscribers = !vm.stopdelayedarrival.isActiveForShipmentSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleCompletedNotificationSubscribers() {
        $('#btn-completed-notification-subscribers').blur();
        vm.shipmentcompleted.isActiveForNotificationSubscribers = !vm.shipmentcompleted.isActiveForNotificationSubscribers;
        var promise = ShipmentNotificationsService.updateCompleted(SentinelUiSession.focus, vm.shipmentcompleted).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.shipmentcompleted = result;
            },
            function (error) {
                vm.shipmentcompleted.isActiveForNotificationSubscribers = !vm.shipmentcompleted.isActiveForNotificationSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleCompletedShipmentSubscribers() {
        $('#btn-completed-shipment-subscribers').blur();
        vm.shipmentcompleted.isActiveForShipmentSubscribers = !vm.shipmentcompleted.isActiveForShipmentSubscribers;
        var promise = ShipmentNotificationsService.updateCompleted(SentinelUiSession.focus, vm.shipmentcompleted).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.shipmentcompleted = result;
            },
            function (error) {
                vm.shipmentcompleted.isActiveForShipmentSubscribers = !vm.shipmentcompleted.isActiveForShipmentSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleCreatedNotificationSubscribers() {
        $('#btn-created-notification-subscribers').blur();
        vm.shipmentcreated.isActiveForNotificationSubscribers = !vm.shipmentcreated.isActiveForNotificationSubscribers;
        var promise = ShipmentNotificationsService.updateCreated(SentinelUiSession.focus, vm.shipmentcreated).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.shipmentcreated = result;
            },
            function (error) {
                vm.shipmentcreated.isActiveForNotificationSubscribers = !vm.shipmentcreated.isActiveForNotificationSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleDepartureNotificationSubscribers() {
        $('#btn-departure-notification-subscribers').blur();
        vm.stopdeparture.isActiveForNotificationSubscribers = !vm.stopdeparture.isActiveForNotificationSubscribers;
        var promise = ShipmentNotificationsService.updateDeparture(SentinelUiSession.focus, vm.stopdeparture).$promise;
        $rootScope.loading = false;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.stopdeparture = result;
            },
            function (error) {
                vm.stopdeparture.isActiveForNotificationSubscribers = !vm.stopdeparture.isActiveForNotificationSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleDelayedDepartureNotificationSubscribers() {
        $('#btn-delayed-departure-notification-subscribers').blur();
        vm.stopdelayeddeparture.isActiveForNotificationSubscribers = !vm.stopdelayeddeparture.isActiveForNotificationSubscribers;
        var promise = ShipmentNotificationsService.updateDelayedDeparture(SentinelUiSession.focus, vm.stopdelayeddeparture).$promise;
        $rootScope.loading = false;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.stopdelayeddeparture = result;
            },
            function (error) {
                vm.stopdelayeddeparture.isActiveForNotificationSubscribers = !vm.stopdelayeddeparture.isActiveForNotificationSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleDepartureShipmentSubscribers() {
        $('#btn-departure-shipment-subscribers').blur();
        vm.stopdeparture.isActiveForShipmentSubscribers = !vm.stopdeparture.isActiveForShipmentSubscribers;
        var promise = ShipmentNotificationsService.updateDeparture(SentinelUiSession.focus, vm.stopdeparture).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.stopdeparture = result;
            },
            function (error) {
                vm.stopdeparture.isActiveForShipmentSubscribers = !vm.stopdeparture.isActiveForShipmentSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleDelayedDepartureShipmentSubscribers() {
        $('#btn-delayed-departure-shipment-subscribers').blur();
        vm.stopdelayeddeparture.isActiveForShipmentSubscribers = !vm.stopdelayeddeparture.isActiveForShipmentSubscribers;
        var promise = ShipmentNotificationsService.updateDelayedDeparture(SentinelUiSession.focus, vm.stopdelayeddeparture).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.stopdelayeddeparture = result;
            },
            function (error) {
                vm.stopdelayeddeparture.isActiveForShipmentSubscribers = !vm.stopdelayeddeparture.isActiveForShipmentSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleOverdueNotificationSubscribers() {
        $('#btn-overdue-notification-subscribers').blur();
        vm.shipmentoverdue.isActiveForNotificationSubscribers = !vm.shipmentoverdue.isActiveForNotificationSubscribers;
        var promise = ShipmentNotificationsService.updateOverdue(SentinelUiSession.focus, vm.shipmentoverdue).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.shipmentoverdue = result;
            },
            function (error) {
                vm.shipmentoverdue.isActiveForNotificationSubscribers = !vm.shipmentoverdue.isActiveForNotificationSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleViewAlarmNotificationSubscribers() {
        $('#btn-viewalarm-notification-subscribers').blur();
        vm.viewalarm.isActiveForNotificationSubscribers = !vm.viewalarm.isActiveForNotificationSubscribers;
        var promise = ShipmentNotificationsService.updateViewAlarm(SentinelUiSession.focus, vm.viewalarm).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.viewalarm = result;
            },
            function (error) {
                vm.viewalarm.isActiveForNotificationSubscribers = !vm.viewalarm.isActiveForNotificationSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleViewAlarmShipmentSubscribers() {
        $('#btn-viewalarm-shipment-subscribers').blur();
        vm.viewalarm.isActiveForShipmentSubscribers = !vm.viewalarm.isActiveForShipmentSubscribers;
        var promise = ShipmentNotificationsService.updateViewAlarm(SentinelUiSession.focus, vm.viewalarm).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                vm.viewalarm = result;
            },
            function (error) {
                vm.viewalarm.isActiveForShipmentSubscribers = !vm.viewalarm.isActiveForShipmentSubscribers;
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleViewAlarmForSubscriber(subscriber) {
        console.log("Before:", subscriber, "isactive", vm.viewalarm.isActiveForShipmentSubscribers);
        $('#btn-viewalarm-' + subscriber.id).blur();
        if (subscriber.isViewAlarmOn) {
            subscriber.notifications = subscriber.notifications.filter(function (value) {
                return value.toLowerCase() !== 'viewalarm';
            });
        } else {
            subscriber.notifications.push('ViewAlarm');
        }
        var promise = ShipmentNotificationsService.updateContact(SentinelUiSession.focus, subscriber).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                subscriber.isViewAlarmOn = !subscriber.isViewAlarmOn;
            },
            function (error) {
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleCompletedForSubscriber(subscriber) {
        console.log("Before:", subscriber);
        $('#btn-completed-' + subscriber.id).blur();
        if (subscriber.isShipmentCompletedOn) {
            subscriber.notifications = subscriber.notifications.filter(function (value) {
                return value.toLowerCase() !== 'shipmentcompleted';
            });
        } else {
            subscriber.notifications.push('ShipmentCompleted');
        }

        var promise = ShipmentNotificationsService.updateContact(SentinelUiSession.focus, subscriber).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                subscriber.isShipmentCompletedOn = !subscriber.isShipmentCompletedOn;
            },
            function (error) {
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleCreatedForSubscriber(subscriber) {
        console.log("Before:", subscriber);
        $('#btn-created-' + subscriber.id).blur();
        if (subscriber.isShipmentCreatedOn) {
            subscriber.notifications = subscriber.notifications.filter(function (value) {
                return value.toLowerCase() !== 'shipmentcreated';
            });
        } else {
            subscriber.notifications.push('ShipmentCreated');
        }

        var promise = ShipmentNotificationsService.updateContact(SentinelUiSession.focus, subscriber).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                subscriber.isShipmentCreatedOn = !subscriber.isShipmentCreatedOn;
            },
            function (error) {
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleOverdueForSubscriber(subscriber) {
        console.log("Before:", subscriber, "Active");
        $('#btn-overdue-' + subscriber.id).blur();
        if (subscriber.isShipmentOverdueOn) {
            subscriber.notifications = subscriber.notifications.filter(function (value) {
                return value.toLowerCase() !== 'shipmentoverdue';
            });
        } else {
            subscriber.notifications.push('ShipmentOverdue');
        }
        console.log(subscriber);
        var promise = ShipmentNotificationsService.updateContact(SentinelUiSession.focus, subscriber).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                subscriber.isShipmentOverdueOn = !subscriber.isShipmentOverdueOn;
            },
            function (error) {
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleArrivalForSubscriber(subscriber) {
        console.log("Before:", subscriber);
        $('#btn-arrival-' + subscriber.id).blur();
        if (subscriber.isStopArrivalOn) {
            subscriber.notifications = subscriber.notifications.filter(function (value) {
                return value.toLowerCase() !== 'stoparrival';
            });
        } else {
            subscriber.notifications.push('StopArrival');
        }

        var promise = ShipmentNotificationsService.updateContact(SentinelUiSession.focus, subscriber).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                subscriber.isStopArrivalOn = !subscriber.isStopArrivalOn;
            },
            function (error) {
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleDelayedArrivalForSubscriber(subscriber) {
        console.log("Before:", subscriber);
        $('#btn-delayed-arrival-' + subscriber.id).blur();
        if (subscriber.isStopDelayedArrivalOn) {
            subscriber.notifications = subscriber.notifications.filter(function (value) {
                return value.toLowerCase() !== 'stopdelayedarrival';
            });
        } else {
            subscriber.notifications.push('StopDelayedArrival');
        }

        var promise = ShipmentNotificationsService.updateContact(SentinelUiSession.focus, subscriber).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                subscriber.isStopDelayedArrivalOn = !subscriber.isStopDelayedArrivalOn;
            },
            function (error) {
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleDepartureForSubscriber(subscriber) {
        console.log("Before:", subscriber);
        $('#btn-departure-' + subscriber.id).blur();
        if (subscriber.isStopDepartureOn) {
            subscriber.notifications = subscriber.notifications.filter(function (value) {
                return value.toLowerCase() !== 'stopdeparture';
            });
        } else {
            subscriber.notifications.push('StopDeparture');
        }

        var promise = ShipmentNotificationsService.updateContact(SentinelUiSession.focus, subscriber).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                subscriber.isStopDepartureOn = !subscriber.isStopDepartureOn;
            },
            function (error) {
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function toggleDelayedDepartureForSubscriber(subscriber) {
        console.log("Before:", subscriber);
        $('#btn-delayed-departure-' + subscriber.id).blur();
        if (subscriber.isStopDelayedDepartureOn) {
            subscriber.notifications = subscriber.notifications.filter(function (value) {
                return value.toLowerCase() !== 'stopdelayeddeparture';
            });
        } else {
            subscriber.notifications.push('StopDelayedDeparture');
        }

        var promise = ShipmentNotificationsService.updateContact(SentinelUiSession.focus, subscriber).$promise;
        $rootScope.loading = true;
        promise.then(
            function (result) {
                $rootScope.loading = false;
                subscriber.isStopDelayedDepartureOn = !subscriber.isStopDelayedDepartureOn;
            },
            function (error) {
                $rootScope.loading = false;
                vm.feedback.addError(error.data?.message || error.message || 'Unknown error');
            }
        );
    }

    function sortBy(propertyName) {
        vm.reverse = (vm.propertyName.toString() === propertyName.toString()) ? !vm.reverse : false;
        vm.propertyName = propertyName;
    }
}
