import angular from "angular";

const replacementsCaps = {
    "Shipments": "Cases",
    "Shipment": "Case",
    "Shipping": "Case",
    "Alarms": "Alerts",
    "Alarm": "Alert",
    "Device": "LifePort",
    "Devices": "LifePorts"
};

const replacementsSentry = {
    "Sentry": "LifePort",
    "Sentries": "LifePorts"
};

const generateLowerCaseMappings = () => {
    const finalMappings = {};
    for (const key in replacementsCaps) {
        finalMappings[key] = replacementsCaps[key];
        if (replacementsCaps[key].includes('LifePort')) {
            finalMappings[key.toLowerCase()] = replacementsCaps[key];
        } else {
            finalMappings[key.toLowerCase()] = replacementsCaps[key].toLowerCase();
        }
    }
    for (const key in replacementsSentry) {
        finalMappings[key] = replacementsSentry[key];
        finalMappings[key.toLowerCase()] = replacementsSentry[key];
        finalMappings[key.toUpperCase()] = replacementsSentry[key];
    }
    return finalMappings;
};

const replacements = generateLowerCaseMappings();
const sortedKeys = Object.keys(replacements).sort((a, b) => b.length - a.length);
const regexPattern = '\\b(' + sortedKeys.map(key => key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|') + ')\\b';
const regex = new RegExp(regexPattern, 'g');

const replaceInAttribute = (node, attributeName) => {
    if (node.hasAttribute(attributeName)) {
        const text = node.getAttribute(attributeName);
        if (text) {
            const newText = text.replace(regex, match => replacements[match]);
            if (newText !== text) {
                node.setAttribute(attributeName, newText);
            }
        }
    }
};

const replaceTextContent = (node) => {
    if (node.nodeType === 3) { // Text node
        if (node.nodeValue) {
            const newValue = node.nodeValue.replace(regex, match => replacements[match]);
            if (newValue !== node.nodeValue) {
                node.nodeValue = newValue;
            }
        }
    } else if (node.nodeType === 1) { // Element node
        replaceInAttribute(node, 'placeholder');
        replaceInAttribute(node, 'title');
        angular.forEach(node.childNodes, replaceTextContent);
    }
};

// term-replacer
angular.module('ui-common')
    .directive('termReplacer', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
        return {
            restrict: 'A',
            scope: {},
            link: function (scope, element) {
                if (!$rootScope.isOrs) {
                    return;
                }

                $timeout(() => replaceTextContent(element[0]), 0);

                const observer = new MutationObserver(mutations => {
                    for (const mutation of mutations) {
                        if (mutation.type === "childList" || mutation.type === "characterData") {
                            $timeout(() => replaceTextContent(element[0]), 0);
                        }
                    }
                });

                const config = {
                    childList: true,
                    characterData: true,
                    subtree: true
                };

                observer.observe(element[0], config);
            }
        };
    }]);
